import api from "../api";

function obterValorHomemHoraPorSetor(idSetor) {
  return new Promise((resolve, reject) => {
    return api
      .get(`api/calculoscustohomem/obter/${idSetor}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  obterValorHomemHoraPorSetor
};
