import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import utilsStorage from "../utils/storage";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    const token = localStorage.getItem("token-gpc");

    if (token == null) {
      next({ name: "Login" });
    } else {
      const usuario = JSON.parse(utilsStorage.obterUsuarioAtualStorage());

      if (to.matched.some(record => record.meta.role.includes(usuario.perfil.id))) {
        next();
      } else {
        next({ name: "Processos" });
      }
    }
  } else {
    next();
  }
});

export default router;
