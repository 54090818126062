import utilsData from "@/utils/data";
import { TIPO_DE_LOG } from "@/constants/geral-constants";
/**
 * Classe que modela os logs.
 */
export default class LogModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.idUsuario = obj.idUsuario;
    this.idEntidade = obj.idEntidade;
    this.tipo = obj.tipoLog;
    this.nomeEntidade = obj.nomeEntidade;
    this.valorOriginal = obj.valorOriginal;
    this.valorAtual = obj.valorAtual;
    this.dataHora = utilsData.aplicarMascaraDataHoraEmDataIso(
      obj.dataHoraLog,
      ""
    );
    this.data = utilsData.aplicarMascaraEmData(obj.dataHoraLog, "");

    if (obj.id !== undefined) {
      const processoCobrado = JSON.parse(this.valorAtual);
      this.codigoClienteString = processoCobrado.CodigoCliente;
      this.idString = JSON.parse(this.valorAtual).Id;
      this.jiraString = JSON.parse(this.valorAtual).LinkTarefaJira;
      this.loginUsuario = processoCobrado.LoginUsuario;
      this.tipoDeLogString = TIPO_DE_LOG[this.tipo].VALUE;
    }
  }
}
