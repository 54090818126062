import utilsData from "@/utils/data";
import { TIPO_APLICACAO, STATUS_PROCESSO } from "@/constants/geral-constants";

/**
 * Classe que modela os processos cobrados.
 */
export default class ProcessoCobradoModel {
  /**
   * Construtor da classe de processo cobrado.
   * @param {Array} obj Contém um objeto os dados de cada usuário.
   */
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.idValoresFixosImpostos = obj.idValoresFixosImpostos;
    this.idSetor = obj.idSetor;
    this.setor = obj.setor || {};
    this.linkTarefaJira = obj.linkTarefaJira;
    this.codigoCliente = obj.codigoCliente;
    this.tipoDeAplicacao = obj.tipoDeAplicacao || 1;
    this.quantidadeConexoes = obj.quantidadeConexoes || 0;
    this.idStatus = obj.idStatus || 3;
    this.status = obj.status || {};
    this.justificativaStatus = obj.justificativaStatus;
    this.observacao = obj.observacao;

    this.valorHoraHomemProgramacao = obj.valorHoraHomemProgramacao;
    this.valorHoraHomemQualidade = obj.valorHoraHomemQualidade;
    this.valorHoraHomemSuporte = obj.valorHoraHomemSuporte;
    this.valorHoraHomemComercial = obj.valorHoraHomemComercial;

    this.horasTrabalhadasProgramacaoHoraNormal =
      obj.horasTrabalhadasProgramacaoHoraNormal || 0;
    this.horasTrabalhadasProgramacaoHoraExtraCinquentaPorCento =
      obj.horasTrabalhadasProgramacaoHoraExtraCinquentaPorCento || 0;
    this.horasTrabalhadasProgramacaoHoraExtraCemPorCento =
      obj.horasTrabalhadasProgramacaoHoraExtraCemPorCento || 0;

    this.horasTrabalhadasQualidadeHoraNormal =
      obj.horasTrabalhadasQualidadeHoraNormal || 0;
    this.horasTrabalhadasQualidadeHoraExtraCinquentaPorCento =
      obj.horasTrabalhadasQualidadeHoraExtraCinquentaPorCento || 0;
    this.horasTrabalhadasQualidadeHoraExtraCemPorCento =
      obj.horasTrabalhadasQualidadeHoraExtraCemPorCento || 0;

    this.horasTrabalhadasSuporteHoraNormal =
      obj.horasTrabalhadasSuporteHoraNormal || 8;
    this.horasTrabalhadasSuporteHoraExtraCinquentaPorCento =
      obj.horasTrabalhadasSuporteHoraExtraCinquentaPorCento || 0;
    this.horasTrabalhadasSuporteHoraExtraCemPorCento =
      obj.horasTrabalhadasSuporteHoraExtraCemPorCento || 0;

    this.horasTrabalhadasComercialHoraNormal =
      obj.horasTrabalhadasComercialHoraNormal || 0;
    this.horasTrabalhadasComercialHoraExtraCinquentaPorCento =
      obj.horasTrabalhadasComercialHoraExtraCinquentaPorCento || 0;
    this.horasTrabalhadasComercialHoraExtraCemPorCento =
      obj.horasTrabalhadasComercialHoraExtraCemPorCento || 0;

    this.pisSobreFaturamento = obj.pisSobreFaturamento;
    this.cofinsSobreFaturamento = obj.cofinsSobreFaturamento;
    this.issSobreFaturamento = obj.issSobreFaturamento;
    this.inssSobreDesoneracao = obj.inssSobreDesoneracao;
    this.custosAdministrativos = obj.custosAdministrativos;
    this.comissionamentoComercial = obj.comissionamentoComercial;

    this.custoTotalComHomemHora = obj.custoTotalComHomemHora;
    this.totalDespesasSemIrpjCsllAdicIrSobreLucro =
      obj.totalDespesasSemIrpjCsllAdicIrSobreLucro;
    this.irpjCsllAdicIrSobreLucro = obj.irpjCsllAdicIrSobreLucro;
    this.valorMinimoParaRecorrencia = obj.valorMinimoParaRecorrencia;
    this.valorMinimoParaCobranca = obj.valorMinimoParaCobranca;

    this.loginUsuario = obj.loginUsuario;
    this.dataHoraCadastro = utilsData.aplicarMascaraEmDataIso(
      obj.dataHoraCadastro
    );
    this.dataHoraCadastroSemFormatar = obj.dataHoraCadastro;

    if (obj.id !== undefined) {
      this.setorString = this.setor.descricao;
      this.statusString = this.status.descricao;
      this.tipoDeAplicacaoString =
        TIPO_APLICACAO.TIPO_APLICACAO_ITEMS[this.tipoDeAplicacao - 1];
    }

    if (this.statusString !== STATUS_PROCESSO.REPROVADO.VALUE) {
      this.justificativaStatus = null;
    }
  }

  /**
   * Método para verificar os dados necessários para um processo cobrado ser válido.
   * @returns {boolean} Retorna um boolean dizendo se o processo cobrado é válido ou não.
   */
  modeloValido() {
    return !!(
      !!this.linkTarefaJira &&
      !!this.codigoCliente &&
      this.horasTrabalhadasProgramacaoValido() &&
      this.horasTrabalhadasQualidadeValido() &&
      this.horasTrabalhadasSuporteValido() &&
      this.horasTrabalhadasComercialValido()
    );
  }

  horasTrabalhadasProgramacaoValido() {
    return !!(
      !isNaN(parseFloat(this.horasTrabalhadasProgramacaoHoraNormal)) &&
      !isNaN(
        parseFloat(this.horasTrabalhadasProgramacaoHoraExtraCinquentaPorCento)
      ) &&
      !isNaN(parseFloat(this.horasTrabalhadasProgramacaoHoraExtraCemPorCento))
    );
  }

  horasTrabalhadasQualidadeValido() {
    return !!(
      !isNaN(parseFloat(this.horasTrabalhadasQualidadeHoraNormal)) &&
      !isNaN(
        parseFloat(this.horasTrabalhadasQualidadeHoraExtraCinquentaPorCento)
      ) &&
      !isNaN(parseFloat(this.horasTrabalhadasQualidadeHoraExtraCemPorCento))
    );
  }

  horasTrabalhadasSuporteValido() {
    return !!(
      !isNaN(parseFloat(this.horasTrabalhadasSuporteHoraNormal)) &&
      !isNaN(
        parseFloat(this.horasTrabalhadasSuporteHoraExtraCinquentaPorCento)
      ) &&
      !isNaN(parseFloat(this.horasTrabalhadasSuporteHoraExtraCemPorCento))
    );
  }

  horasTrabalhadasComercialValido() {
    return !!(
      !isNaN(parseFloat(this.horasTrabalhadasComercialHoraNormal)) &&
      !isNaN(
        parseFloat(this.horasTrabalhadasComercialHoraExtraCinquentaPorCento)
      ) &&
      !isNaN(parseFloat(this.horasTrabalhadasComercialHoraExtraCemPorCento))
    );
  }
}
