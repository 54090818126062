import { STATUS_USUARIO, PERFIL_USUARIO } from "@/constants/geral-constants.js";

/**
 * Classe de usuário.
 */
export default class UsuarioModel {
  /**
   * Construtor da classe de usuário.
   * @param {Array} obj Contém um objeto os dados de cada usuário.
   */
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.login = obj.login;
    this.email = obj.email;
    this.perfil = obj.perfil;
    this.idPerfil = obj.idPerfil;
    this.setoresUsuario = obj.setoresUsuario;
    this.status = obj.status;

    if (obj.id !== undefined) {
      this.perfilString = this.perfil.descricao;
      // this.setorString = this.setoresUsuario[0].descricao;
      this.tipoStatusString = STATUS_USUARIO.STATUS_ITEMS[this.status];
    }
  }

  /**
   * Método para verificar os dados necessários para um usuário ser válido.
   * @returns {boolean} Retorna um boolean dizendo se o usuário é válido ou não.
   */
  modeloValido() {
    return !!(this.login && this.email && this.idPerfil && this.setoresUsuario);
  }

  eAdmin() {
    if(this.perfil && this.perfil.id){
      return (PERFIL_USUARIO.ADMIN.ID === this.perfil.id);
    }

    return false;
  }

}
