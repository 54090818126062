import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import VueTheMask from "vue-the-mask";
import money from "v-money";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@/static/styles/_index.scss";

Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.use(money, { precision: 2 });
Vue.use(VueMask);
Vue.use(Highcharts);
Vue.use(HighchartsVue);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
