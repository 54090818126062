import Login from "@/views/Login/Login.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import Auditoria from "@/views/auditoria/Auditoria.vue";
import Configuracoes from "@/views/configuracoes/Configuracoes.vue"
import ControleDeProcessos from "@/views/processos/ControleDeProcessos.vue";
import ProcessoCobrado from "@/views/processos/ProcessoCobrado.vue";
import ControleUsuarios from "@/views/controle-usuarios/ControleUsuarios.vue";
import { PERFIL_USUARIO } from "@/constants/geral-constants.js";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    title: "Login",
    icon: "",
    menu: false,
    meta: { requireAuth: false }
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    title: "Dashboard",
    icon: "mdi-dashboard",
    menu: true,
    meta: {
      requireAuth: true,
      role: [PERFIL_USUARIO.ADMIN.ID, PERFIL_USUARIO.MANAGER.ID, PERFIL_USUARIO.BOARD.ID, PERFIL_USUARIO.STANDARD.ID]
    }
  },
  {
    path: "/auditoria",
    name: "Auditoria",
    component: Auditoria,
    title: "Auditoria",
    icon: "mdi-file-chart",
    menu: true,
    meta: {
      requireAuth: true,
      role:
        [PERFIL_USUARIO.ADMIN.ID, PERFIL_USUARIO.MANAGER.ID, PERFIL_USUARIO.BOARD.ID]
    }
  },
  {
    path: "/configuracoes",
    name: "Configuracoes",
    component: Configuracoes,
    title: "Configuracões",
    icon: "mdi-cog",
    menu: true,
    meta: {
      requireAuth: true,
      role: [PERFIL_USUARIO.ADMIN.ID]
    }
  },
  {
    path: "/processos",
    name: "Processos",
    component: ControleDeProcessos,
    title: "Processos",
    icon: "mdi-view-dashboard",
    menu: true,
    meta: {
      requireAuth: true,
      role: [PERFIL_USUARIO.ADMIN.ID, PERFIL_USUARIO.MANAGER.ID, PERFIL_USUARIO.BOARD.ID, PERFIL_USUARIO.STANDARD.ID]
    }
  },
  {
    path: "/processos/novo",
    name: "NovoProcesso",
    component: ProcessoCobrado,
    title: "Novo processo",
    icon: "mdi-view-dashboard",
    menu: true,
    meta: {
      requireAuth: true,
      role: [PERFIL_USUARIO.ADMIN.ID, PERFIL_USUARIO.MANAGER.ID, PERFIL_USUARIO.BOARD.ID, PERFIL_USUARIO.STANDARD.ID]
    },
    props: () => ({
      titulo: "Novo processo",
      descricaoBotaoPrimario: "Salvar",
      descricaoBotaoSecundario: "Cancelar",
      novoProcessoProp: true
    })
  },
  {
    path: "/processos/editar/:id",
    name: "EditarProcesso",
    component: ProcessoCobrado,
    title: "Editar processo",
    icon: "mdi-view-dashboard",
    menu: true,
    meta: {
      requireAuth: true,
      role: [PERFIL_USUARIO.ADMIN.ID, PERFIL_USUARIO.MANAGER.ID, PERFIL_USUARIO.BOARD.ID, PERFIL_USUARIO.STANDARD.ID]
    },
    props: route => ({
      titulo: "Editar processo",
      descricaoBotaoPrimario: "Salvar",
      descricaoBotaoSecundario: "Cancelar",
      campoDesabilitadoProp: false,
      processoId: Number(route.params.id),
      ...route.params
    })
  },
  {
    path: "/processos/visualizar/:id",
    name: "VisualizarProcesso",
    component: ProcessoCobrado,
    title: "Visualizar processo",
    icon: "mdi-view-dashboard",
    menu: true,
    meta: {
      requireAuth: true,
      role: [PERFIL_USUARIO.ADMIN.ID, PERFIL_USUARIO.MANAGER.ID, PERFIL_USUARIO.BOARD.ID, PERFIL_USUARIO.STANDARD.ID]
    },
    props: route => ({
      titulo: "Visualizar processo",
      descricaoBotaoSecundario: "Fechar",
      campoDesabilitadoProp: true,
      ...route.params
    })
  },
  {
    path: "/processos/visualizar/:id/publico",
    name: "VisualizarProcessoLinkPublico",
    component: ProcessoCobrado,
    title: "Visualizar processo público",
    icon: "",
    menu: false,
    meta: { requireAuth: false },
    props: route => ({
      titulo: "Visualizar processo",
      descricaoBotaoSecundario: "Fechar",
      campoDesabilitadoProp: true,
      processoPublico: true,
      ...route.params
    })
  },
  {
    path: "/controle-de-usuarios",
    name: "ControleUsuarios",
    component: ControleUsuarios,
    title: "Controle de usuarios",
    icon: "mdi-account",
    menu: true,
    meta: {
      requireAuth: true,
      role: [PERFIL_USUARIO.ADMIN.ID, PERFIL_USUARIO.MANAGER.ID]
    }
  }
];

export default routes;
