import api from "../api";

function logar(usuario) {
  usuario = usuario || {};
  usuario.login =
    (usuario.login && usuario.login.toLowerCase()) || usuario.login;
  return new Promise((resolve, reject) => {
    return api
      .post("/api/usuarios/login", {
        login: usuario.login,
        senha: usuario.senha
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function cadastrar(usuario) {
  usuario = usuario || {};
  return new Promise((resolve, reject) => {
    return api
      .post("/api/usuarios/adicionar", usuario)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function atualizar(usuario) {
  usuario = usuario || {};
  return new Promise((resolve, reject) => {
    return api
      .put(`/api/usuarios/atualizar/${usuario.id}`, usuario)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function atualizarStatusDoUsuario(usuario) {
  usuario = usuario || {};
  return new Promise((resolve, reject) => {
    return api
      .put(`/api/usuarios/atualizarStatusDoUsuario/${usuario.id}/${usuario.status}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterPorId(id) {
  return new Promise((resolve, reject) => {
    return api
      .get(`/api/usuarios/obter/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterTodos(setores, idPerfil) {
  return new Promise((resolve, reject) => {
    return api
      .get(`/api/usuarios/obter-todos?${setores}idPerfil=${idPerfil}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  logar,
  cadastrar,
  atualizar,
  atualizarStatusDoUsuario,
  obterPorId,
  obterTodos
};
