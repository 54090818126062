<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="elevation-3">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="pesquisar"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details></v-text-field>
          </v-col>
        </v-card-title>
        <v-data-table
          :headers="colunas"
          :items="logsFiltrados"
          :search="pesquisar">
          <!-- eslint-disable-next-line -->
          <template v-slot:item.idString="{ item }">
            {{ item.idString }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.codigoCliente="{ item }">
            {{ item.codigoClienteString }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.jiraString="{ item }">
            <a target="_black" :href="item.jiraString">{{
                item.jiraString.length > 50
                  ? item.jiraString.substr(0, 49) + "..."
                  : item.jiraString
            }}</a>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.original="{ item }">
            <v-tooltip top>
              <template
                v-slot:activator="{ on, attrs }"
                v-if="item.valorOriginal">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="20"
                  color="primary"
                  class="mr-1"
                  @click="manipuladorModal(item, logOriginal)">
                  mdi-file-find
                </v-icon>
              </template>
              <span>Visualizar valor original</span>
            </v-tooltip>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.atual="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="20"
                  color="primary"
                  class="mr-1"
                  @click="manipuladorModal(item, !logOriginal)">
                  mdi-file-find
                </v-icon>
              </template>
              <span>Visualizar valor atual</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <!-- Modal de logs -->
        <v-dialog v-model="modal" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="titulo-modal">Valor {{ tituloLog }}</span>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-row class="mt-1">
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Criado por"
                      readonly
                      :error="verificaExistenciaNoArray('LoginUsuario')"
                      :hint="
                        verificaExistenciaNoArray('LoginUsuario')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.loginUsuario"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Linha de produto"
                      readonly
                      :error="verificaExistenciaNoArray('IdSetor')"
                      :hint="
                        verificaExistenciaNoArray('IdSetor')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.setorString"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Status"
                      readonly
                      :error="verificaExistenciaNoArray('IdStatus')"
                      :hint="
                        verificaExistenciaNoArray('IdStatus')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.statusString"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Link da tarefa"
                      readonly
                      :error="verificaExistenciaNoArray('LinkTarefaJira')"
                      :hint="
                        verificaExistenciaNoArray('LinkTarefaJira')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.linkTarefaJira"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Código do cliente"
                      readonly
                      :error="verificaExistenciaNoArray('CodigoCliente')"
                      :hint="
                        verificaExistenciaNoArray('CodigoCliente')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.codigoCliente"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Tipo de aplicação"
                      readonly
                      :error="verificaExistenciaNoArray('TipoDeAplicacao')"
                      :hint="
                        verificaExistenciaNoArray('TipoDeAplicacao')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.tipoDeAplicacao"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Valor Hora/Homem Programação"
                      readonly
                      :error="
                        verificaExistenciaNoArray('ValorHoraHomemProgramacao')
                      "
                      :hint="
                        verificaExistenciaNoArray('ValorHoraHomemProgramacao')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.valorHoraHomemProgramacao"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Valor Hora/Homem Qualidade"
                      readonly
                      :error="
                        verificaExistenciaNoArray('ValorHoraHomemQualidade')
                      "
                      :hint="
                        verificaExistenciaNoArray('ValorHoraHomemQualidade')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.valorHoraHomemQualidade"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Valor Hora/Homem Suporte"
                      readonly
                      :error="
                        verificaExistenciaNoArray('ValorHoraHomemSuporte')
                      "
                      :hint="
                        verificaExistenciaNoArray('ValorHoraHomemSuporte')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.valorHoraHomemSuporte"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Valor Hora/Homem Comercial"
                      readonly
                      :error="
                        verificaExistenciaNoArray('ValorHoraHomemComercial')
                      "
                      :hint="
                        verificaExistenciaNoArray('ValorHoraHomemComercial')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.valorHoraHomemComercial"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas programação"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasProgramacaoHoraNormal'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasProgramacaoHoraNormal'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasProgramacaoHoraNormal
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas programação Extra 50%"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasProgramacaoHoraExtraCinquentaPorCento'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasProgramacaoHoraExtraCinquentaPorCento'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasProgramacaoHoraExtraCinquentaPorCento
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas programação Extra 100%"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasProgramacaoHoraExtraCemPorCento'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasProgramacaoHoraExtraCemPorCento'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasProgramacaoHoraExtraCemPorCento
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas qualidade"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasQualidadeHoraNormal'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasQualidadeHoraNormal'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasQualidadeHoraNormal
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas qualidade Extra 50%"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasQualidadeHoraExtraCinquentaPorCento'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasQualidadeHoraExtraCinquentaPorCento'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasQualidadeHoraExtraCinquentaPorCento
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas qualidade Extra 100%"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasQualidadeHoraExtraCemPorCento'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasQualidadeHoraExtraCemPorCento'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasQualidadeHoraExtraCemPorCento
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas Suporte"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasSuporteHoraNormal'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasSuporteHoraNormal'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasSuporteHoraNormal
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas suporte Extra 50%"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasSuporteHoraExtraCinquentaPorCento'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasSuporteHoraExtraCinquentaPorCento'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasSuporteHoraExtraCinquentaPorCento
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas suporte Extra 100%"
                      readonlyx
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasSuporteHoraExtraCemPorCento'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasSuporteHoraExtraCemPorCento'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasSuporteHoraExtraCemPorCento
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas comercial"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasComercialHoraNormal'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasComercialHoraNormal'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasComercialHoraNormal
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas comercial Extra 50%"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasComercialHoraExtraCinquentaPorCento'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasComercialHoraExtraCinquentaPorCento'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasComercialHoraExtraCinquentaPorCento
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Horas trabalhadas comercial Extra 100%"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasComercialHoraExtraCemPorCento'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'HorasTrabalhadasComercialHoraExtraCemPorCento'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.horasTrabalhadasComercialHoraExtraCemPorCento
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Custo total com homem hora"
                      readonly
                      :error="
                        verificaExistenciaNoArray('CustoTotalComHomemHora')
                      "
                      :hint="
                        verificaExistenciaNoArray('CustoTotalComHomemHora')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.custoTotalComHomemHora"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Pis sobre faturamento"
                      readonly
                      :error="verificaExistenciaNoArray('PisSobreFaturamento')"
                      :hint="
                        verificaExistenciaNoArray('PisSobreFaturamento')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.pisSobreFaturamento"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Cofins sobre faturamento"
                      readonly
                      :error="
                        verificaExistenciaNoArray('CofinsSobreFaturamento')
                      "
                      :hint="
                        verificaExistenciaNoArray('CofinsSobreFaturamento')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.cofinsSobreFaturamento"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="ISS sobre faturamento"
                      readonly
                      :error="verificaExistenciaNoArray('IssSobreFaturamento')"
                      :hint="
                        verificaExistenciaNoArray('IssSobreFaturamento')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.issSobreFaturamento"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="INSS sobre desoneração"
                      readonly
                      :error="verificaExistenciaNoArray('InssSobreDesoneracao')"
                      :hint="
                        verificaExistenciaNoArray('InssSobreDesoneracao')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.inssSobreDesoneracao"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Custos administrativos"
                      readonly
                      :error="
                        verificaExistenciaNoArray('CustosAdministrativos')
                      "
                      :hint="
                        verificaExistenciaNoArray('CustosAdministrativos')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.custosAdministrativos"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Comissionamento comercial"
                      readonly
                      :error="
                        verificaExistenciaNoArray('ComissionamentoComercial')
                      "
                      :hint="
                        verificaExistenciaNoArray('ComissionamentoComercial')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.comissionamentoComercial"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Total despesas sem IrpjCsllAdicIr sobre lucro"
                      readonly
                      :error="
                        verificaExistenciaNoArray(
                          'TotalDespesasSemIrpjCsllAdicIrSobreLucro'
                        )
                      "
                      :hint="
                        verificaExistenciaNoArray(
                          'TotalDespesasSemIrpjCsllAdicIrSobreLucro'
                        )
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="
                        processoCobrado.totalDespesasSemIrpjCsllAdicIrSobreLucro
                      "></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="IrpjCsllAdicIr sobre lucro"
                      readonly
                      :error="
                        verificaExistenciaNoArray('IrpjCsllAdicIrSobreLucro')
                      "
                      :hint="
                        verificaExistenciaNoArray('IrpjCsllAdicIrSobreLucro')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.irpjCsllAdicIrSobreLucro"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Quantidade conexoes"
                      readonly
                      :error="verificaExistenciaNoArray('QuantidadeConexoes')"
                      :hint="
                        verificaExistenciaNoArray('QuantidadeConexoes')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.quantidadeConexoes"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Valor mínimo para recorrência"
                      readonly
                      :error="
                        verificaExistenciaNoArray('ValorMinimoParaRecorrencia')
                      "
                      :hint="
                        verificaExistenciaNoArray('ValorMinimoParaRecorrencia')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.valorMinimoParaRecorrencia"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Valor mínimo para cobrança"
                      readonly
                      :error="
                        verificaExistenciaNoArray('ValorMinimoParaCobranca')
                      "
                      :hint="
                        verificaExistenciaNoArray('ValorMinimoParaCobranca')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.valorMinimoParaCobranca"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-textarea
                      outlined
                      auto-grow
                      v-if="processoCobrado.justificativaStatus"
                      label="Justificativa"
                      readonly
                      :error="verificaExistenciaNoArray('JustificativaStatus')"
                      :hint="
                        verificaExistenciaNoArray('JustificativaStatus')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.justificativaStatus"></v-textarea>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      outlined
                      auto-grow
                      v-if="processoCobrado.observacao"
                      label="observação"
                      readonly
                      :error="verificaExistenciaNoArray('Observacao')"
                      :hint="
                        verificaExistenciaNoArray('Observacao')
                          ? 'Valor modificado'
                          : ''
                      "
                      persistent-hint
                      v-model="processoCobrado.observacao"></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="btn-tertiary elevation-1"
                text
                small
                @click="cancelar">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ProcessoCobradoModel from "@/model/processo-cobrado-model.js";
import { SETOR } from "@/constants/geral-constants.js";

export default {
  nome: "TabelaDeLog",
  data() {
    return {
      pesquisar: "",
      processoCobrado: new ProcessoCobradoModel(),
      valorComparado: [],
      logOriginal: true,
      modal: false,
      tituloLog: false,
      colunas: [
        {
          text: "Processo",
          value: "idString",
          align: "left"
        },
        {
          text: "Jira",
          value: "jiraString",
          align: "left"
        },
        {
          text: "Cliente",
          value: "codigoClienteString",
          align: "center"
        },
        {
          text: "Tipo",
          value: "tipoDeLogString",
          align: "center"
        },
        {
          text: "Valor original",
          value: "original",
          sortable: false,
          align: "center"
        },
        {
          text: "Valor atual",
          value: "atual",
          sortable: false,
          align: "center"
        },
        {
          text: "Data hora",
          value: "dataHora",
          align: "center"
        },
        {
          text: "Criado por",
          value: "loginUsuario",
          align: "left"
        }
      ]
    };
  },
  props: {
    logsFiltrados: { type: Array, default: () => [] }
  },
  methods: {
    manipuladorModal({ valorOriginal, valorAtual }, logOriginal) {
      this.valorComparado = [];
      let log = {};

      const valorLogOriginal = JSON.parse(valorOriginal) || {};
      const valorLogAtual = JSON.parse(valorAtual);

      if (logOriginal) {
        this.tituloLog = "Original";
        log = valorLogOriginal;
      } else {
        this.tituloLog = "Atual";
        this.comparar(valorLogAtual, valorLogOriginal);
        log = valorLogAtual;
      }

      this.processoCobrado = new ProcessoCobradoModel(
        this.converteParaCamelCase(log)
      );

      this.modal = true;

      if (log.IdSetor === SETOR.BIMER.ID) {
        this.processoCobrado.IdSetor === SETOR.BIMER.VALUE;
      } else if (log.IdSetor === SETOR.SHOP.ID) {
        this.processoCobrado.IdSetor === SETOR.SHOP.VALUE;
      } else {
        this.processoCobrado.IdSetor === SETOR.PACK.VALUE;
      }
    },

    converteParaCamelCase(obj) {
      let objeto, key, novaKey, valor;

      objeto = {};
      for (key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          novaKey = (
            key.charAt(0).toLowerCase() + key.slice(1) || key
          ).toString();
          valor = obj[key];
          if (valor !== null && typeof valor === "object") {
            valor = this.converteParaCamelCase(valor);
          }
          objeto[novaKey] = valor;
        }
      }
      return objeto;
    },

    comparar(valorAtual, valorOriginal) {
      if (Object.keys(valorOriginal).length === 0) return;

      for (const chave in valorAtual) {
        if (
          chave !== "ValoresFixosImpostos" &&
          chave !== "Status" &&
          chave !== "Setor"
        ) {
          if (valorOriginal[chave] !== valorAtual[chave]) {
            this.valorComparado.push(chave);
          }
        }
      }
      return this.valorComparado;
    },

    verificaExistenciaNoArray(string) {
      return this.valorComparado.includes(string);
    },

    cancelar() {
      this.modal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.titulo-modal {
  font-size: 18px;
  color: $cor_primaria;
}
</style>
