function obterUsuarioAtualStorage() {
  var usuario = localStorage.getItem("usuario-gpc");

  if (usuario) {
    usuario = atob(usuario);
    return JSON.parse(usuario);
  }
  return undefined;
}

function salvarUsuarioNaStorage(usuario) {
  usuario = btoa(JSON.stringify(usuario));
  localStorage.setItem("usuario-gpc", usuario);
}

function salvarTokenNaStorage(token) {
  localStorage.setItem("token-gpc", token);
}

function obterTokenNaStorage() {
  return localStorage.getItem("token-gpc");
}

function removerAutenticacao() {
  localStorage.removeItem("token-gpc");
  localStorage.removeItem("usuario-gpc");
}

export default {
  obterUsuarioAtualStorage,
  salvarUsuarioNaStorage,
  salvarTokenNaStorage,
  obterTokenNaStorage,
  removerAutenticacao
};
