<template>
  <v-container fluid class="pa-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped>
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <h2>Auditoria</h2>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex align-center">
      <v-col cols="12" md="2" class="mb-2"> </v-col>

      <v-col cols="12" md="10">
        <v-form class="d-flex align-center justify-end">
          <v-col cols="12" md="2">
            <DatePicker v-model="dataInicialFiltro" label="Período inicial" />
          </v-col>
          <v-col cols="12" md="2">
            <DatePicker v-model="dataFinalFiltro" label="Período final" />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="d-flex mb-2 pa-0 align-center justify-end">
            <v-btn
              class="btn-tertiary elevation-1 ma-0"
              @click.prevent="filtrarPeriodoEntreDatas"
              text>Filtrar</v-btn>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
    <v-data-table 
            v-show="isLoading"
            item-key="name"
            class="elevation-1"
            loading
            loading-text="Buscando logs..."
          ></v-data-table> 

    <TabelaDeLog v-show="!isLoading" :logsFiltrados="logsFiltrados" />
  </v-container>
</template>

<script>
import mixinMensagen from "@/mixin/mixin-mensagem";
import TabelaDeLog from "@/components/tabela/TabelaDeLog.vue";
import DatePicker from "@/components/comum/DatePicker.vue";
import apiLog from "@/api/log/log-api";
import LogModel from "@/model/log-model";
import utilsStorage from "@/utils/storage";

export default {
  name: "Auditoria",
  components: {
    TabelaDeLog,
    DatePicker
  },
  mixins: [mixinMensagen],
  data() {
    return {
      isLoading: true,
      logs: [],
      logsFiltrados: [],
      dataInicialFiltro: null,
      dataFinalFiltro: null
    };
  },

  methods: {
    obterLogs() {
      const usuario = JSON.parse(utilsStorage.obterUsuarioAtualStorage());
      let setores = "";
      usuario.setoresUsuario.forEach(s => (setores += `setores=${s.id}&`));
      apiLog
        .obter(setores, usuario.perfil.id)
        .then(response => {
          this.logs = response.data.map(log => new LogModel(log));
          this.logsFiltrados = Object.assign(this.logs);
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.dispararMensagem(
            error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
            "error"
          )
        }
        );
    },
    filtrarPeriodoEntreDatas() {
      this.logsFiltrados = [];
      this.logs.filter(item => {
        if (
          new Date(item.data).getTime() >=
          new Date(this.dataInicialFiltro).getTime() &&
          new Date(item.data).getTime() <=
          new Date(this.dataFinalFiltro).getTime()
        ) {
          this.logsFiltrados.push(item);
        } else if (
          new Date(item.data).getTime() >=
          new Date(this.dataInicialFiltro).getTime() &&
          this.dataFinalFiltro == null
        ) {
          this.logsFiltrados.push(item);
        } else if (
          this.dataInicialFiltro == null &&
          new Date(item.data).getTime() <=
          new Date(this.dataFinalFiltro).getTime()
        ) {
          this.logsFiltrados.push(item);
        } else if (
          this.dataInicialFiltro == null &&
          this.dataFinalFiltro == null
        ) {
          this.logsFiltrados.push(item);
        }
      });
    }
  },
  beforeMount() {
    this.obterLogs();
  }
};
</script>

<style lang="scss" scoped>
.filtro {
  display: flex;
  height: 4rem;
}
</style>
