var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-3"},[_c('v-card-title',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.pesquisar),callback:function ($$v) {_vm.pesquisar=$$v},expression:"pesquisar"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.colunas,"items":_vm.processosCobrados,"item-key":"id","search":_vm.pesquisar},scopedSlots:_vm._u([{key:"item.linkTarefaJira",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_black","href":item.linkTarefaJira}},[_vm._v(" "+_vm._s(item.linkTarefaJira.length > 45 ? item.linkTarefaJira.substr(0, 44) + "..." : item.linkTarefaJira)+" ")])]}},{key:"item.valorMinimoParaCobranca",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatarDinheiro")(item.valorMinimoParaCobranca))+" ")]}},{key:"item.statusString",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 chip",attrs:{"color":item.statusString === _vm.statusPendente
                ? 'blue'
                : item.statusString === _vm.statusAprovado
                ? 'green'
                : item.statusString === _vm.statusReprovado
                ? 'orange'
                : 'red',"text-color":"white","small":"","close":_vm.verificaPresencaDeJustificativaDeReprovacao(item),"close-label":"Justificativa","close-icon":"mdi-information-outline"},on:{"click:close":function($event){return _vm.abrirModal(item)}}},[_vm._v(_vm._s(item.statusString))])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.visualizarProcessoCobrado(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar processo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",staticStyle:{"width":"10gpx"},attrs:{"small":"","color":"primary","disabled":_vm.modoVisualizacao},on:{"click":function($event){return _vm.editarProcessoCobrado(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar processo")])])]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6",attrs:{"id":"modal-title"}},[_vm._v(" Justificativa de reprovação ")]),_c('v-card-text',{staticClass:"mt-4"},[_c('p',{attrs:{"id":"modal-text"},domProps:{"innerHTML":_vm._s(_vm.descricao)}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-tertiary elevation-1",attrs:{"small":"","text":""},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }