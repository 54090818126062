/**
 * Classe de valor por setor.
 */
 export default class ValorPorSetorModel {
  /**
   * Construtor da classe.
   * @param {Array} obj Objeto da requisição.
   */
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.funcao = obj.funcao;
    this.idSetor = obj.idSetor;
    this.salarioBase = obj.salarioBase;
    this.totalDeHorasNoMes = obj.totalDeHorasNoMes;
  }
}
