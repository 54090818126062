<template>
  <v-row>
    <v-col cols="12">
      <v-card class="elevation-3">
        <v-card-title>
          <v-spacer />
          <v-col cols="12" md="4">
            <v-text-field
              v-model="pesquisar"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-data-table
          :headers="colunas"
          :items="processosCobrados"
          item-key="id"
          :search="pesquisar"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.linkTarefaJira="{ item }">
            <a target="_black" :href="item.linkTarefaJira">
              {{
                item.linkTarefaJira.length > 45
                  ? item.linkTarefaJira.substr(0, 44) + "..."
                  : item.linkTarefaJira
              }}
            </a>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.valorMinimoParaCobranca="{ item }">
            {{ item.valorMinimoParaCobranca | formatarDinheiro }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.statusString="{ item }">
            <v-chip
              :color="
                item.statusString === statusPendente
                  ? 'blue'
                  : item.statusString === statusAprovado
                  ? 'green'
                  : item.statusString === statusReprovado
                  ? 'orange'
                  : 'red'
              "
              text-color="white"
              small
              :close="verificaPresencaDeJustificativaDeReprovacao(item)"
              @click:close="abrirModal(item)"
              close-label="Justificativa"
              close-icon="mdi-information-outline"
              class="ma-2 chip"
              >{{ item.statusString }}</v-chip
            >
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-1"
                  color="primary"
                  @click="visualizarProcessoCobrado(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>Visualizar processo</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  style="width: 10gpx"
                  class="mr-1"
                  color="primary"
                  :disabled="modoVisualizacao"
                  @click="editarProcessoCobrado(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar processo</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-dialog v-model="modal" width="800">
      <v-card>
        <v-card-title id="modal-title" class="text-h6">
          Justificativa de reprovação
        </v-card-title>
        <v-card-text class="mt-4">
          <p id="modal-text" v-html="descricao"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-tertiary elevation-1"
            small
            text
            @click="modal = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { STATUS_PROCESSO } from "../../constants/geral-constants";

export default {
  props: {
    processosCobrados: { type: Array, default: () => [] },
    modoVisualizacao: { type: Boolean, default: false }
  },
  filters: {
    formatarDinheiro(valor) {
      return Number(valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    }
  },
  data() {
    return {
      statusAprovado: STATUS_PROCESSO.APROVADO.VALUE,
      statusPendente: STATUS_PROCESSO.PENDENTE.VALUE,
      statusReprovado: STATUS_PROCESSO.REPROVADO.VALUE,
      pesquisar: "",
      descricao: "",
      modal: false,
      colunas: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        {
          text: "Jira",
          value: "linkTarefaJira"
        },
        {
          text: "Cliente",
          value: "codigoCliente",
          align: "center"
        },
        {
          text: "Status",
          align: "center",
          value: "statusString"
        },
        {
          text: "Tipo",
          align: "start",
          value: "tipoDeAplicacaoString"
        },
        {
          text: "Criado por",
          align: "start",
          value: "loginUsuario"
        },
        {
          text: "Cadastro",
          align: "end",
          value: "dataHoraCadastro"
        },
        {
          text: "Valor cobrança",
          align: "end",
          value: "valorMinimoParaCobranca"
        },
        {
          text: null,
          value: "actions",
          align: "center",
          sortable: false,
          width: "80"
        }
      ]
    };
  },
  methods: {
    visualizarProcessoCobrado(item) {
      this.$router.push({
        name: "VisualizarProcesso",
        params: { id: item.id }
      });
    },
    editarProcessoCobrado(item) {
      this.$router.push({
        name: "EditarProcesso",
        params: { id: item.id }
      });
    },
    verificaPresencaDeJustificativaDeReprovacao(item) {
      if (item.justificativaStatus) {
        return true;
      }
    },
    abrirModal(item) {
      this.descricao = item.justificativaStatus;
      this.modal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input--hide-details > .v-input__control > .v-input__slot {
  padding: 1rem;
  max-width: 100px;
}
#modal-text {
  font-size: 16px;
}
#modal-title {
  font-size: 18px;
  color: $cor_primaria;
}
.chip {
  width: 7.4rem;
  justify-content: center;
}
.bloco-expansivel {
  padding: 20px;
  background: #f5f5f5;
}
</style>
