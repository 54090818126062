<template>
  <v-app>
    <Menu
      v-if="
        this.$router.currentRoute.name != 'VisualizarProcessoLinkPublico' &&
        this.$router.currentRoute.name != 'Login'
      "></Menu>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped>
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Menu from "@/components/menu/Menu";
import MixinMensagem from "@/mixin/mixin-mensagem";

export default {
  name: "App",
  mixins: [MixinMensagem],
  components: {
    Menu
  }
};
</script>
<style lang="scss">

</style>
