"use strict";

import moment from "moment";

function aplicarMascaraEmDataIso(data, valorDataVazia) {
  if (data) {
    return moment(data).format("DD/MM/YYYY");
  }

  return valorDataVazia ? valorDataVazia : null;
}
function aplicarMascaraEmData(data, valorDataVazia) {
  if (data) {
    return moment(data).format("YYYY/MM/DD");
  }

  return valorDataVazia ? valorDataVazia : null;
}

function aplicarMascaraDataHoraEmDataIso(data, valorDataVazia) {
  if (data) {
    return moment(data).format("DD/MM/YYYY HH:mm:ss");
  }

  return valorDataVazia ? valorDataVazia : null;
}

function aplicarMascaraDataHoraSemSegundos(data, valorDataVazia) {
  if (data) {
    return moment(data).format("DD/MM/YYYY HH:mm");
  }

  return valorDataVazia ? valorDataVazia : null;
}
function formatarDataEHoraIso(data, horario) {
  return `${moment(data).format("YYYY-MM-DD")}T${horario || "00:00:00"}`;
}

function formatarDataQueryString(data) {
  return `${moment(data).format('YYYY-MM-DD')}T23:59:59`;
}

function dataFinalMaiorOuIgualDataInicial(dataInicial, dataFinal) {
  return (
    !dataInicial ||
    dataInicial == "" ||
    !dataFinal ||
    dataFinal == "" ||
    new Date(dataInicial) <= new Date(dataFinal)
  );
}

export default {
  dataFinalMaiorOuIgualDataInicial,
  aplicarMascaraDataHoraEmDataIso,
  aplicarMascaraDataHoraSemSegundos,
  formatarDataEHoraIso,
  formatarDataQueryString,
  aplicarMascaraEmDataIso,
  aplicarMascaraEmData
};
