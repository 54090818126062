<template>
  <v-container fluid class="pa-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <h2>Processos cobrados</h2>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex align-center">
      <v-col cols="12" md="2" class="mb-2">
        <v-btn
          @click="navegarNovoProcesso"
          class="btn-primary elevation-1 ma-0"
          text
          :disabled="modoVisualizacao"
          >Adicionar</v-btn
        >
      </v-col>

      <v-col cols="12" md="10">
        <v-form class="d-flex align-center justify-end">
          <v-col cols="12" md="2">
            <DatePicker v-model="dataInicial" label="Período inicial" />
          </v-col>
          <v-col cols="12" md="2">
            <DatePicker v-model="dataFinal" label="Período final" />
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              :items="statusProcessoItems"
              v-model="statusProcessoFiltroString"
              label="Status"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              :items="tipoAplicacaoItems"
              v-model="tipoAplicacaoFiltroString"
              label="Tipo Aplicação"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="d-flex mb-2 pa-0 align-center justify-end"
          >
            <v-btn
              class="btn-tertiary elevation-1 ma-0"
              @click.prevent="filtrarProcessos"
              text
              >Filtrar</v-btn
            >
          </v-col>
        </v-form>
      </v-col>
    </v-row>

    <v-data-table
      v-show="isLoading"
      item-key="name"
      class="elevation-1"
      loading
      loading-text="Buscando processos..."
    ></v-data-table>

    <TabelaProcessosCobrados
      v-show="!isLoading"
      :processosCobrados="processosCobrados"
      :modoVisualizacao="modoVisualizacao"
    />
  </v-container>
</template>

<script>
import DatePicker from "@/components/comum/DatePicker.vue";
import TabelaProcessosCobrados from "@/components/tabela/TabelaProcessosCobrados.vue";
import ProcessoCobradoModel from "@/model/processo-cobrado-model";
import UsuarioModel from "@/model/usuario-model";
import mixinMensagen from "@/mixin/mixin-mensagem";
import apiProcessoCobrado from "@/api/processo-cobrado/processo-cobrado-api";
import utilsStorage from "@/utils/storage";
import {
  PERFIL_USUARIO,
  STATUS_PROCESSO,
  MES_ITEMS,
  TIPO_APLICACAO,
} from "@/constants/geral-constants.js";

export default {
  name: "ControleDeProcessos",
  mixins: [mixinMensagen],
  components: {
    TabelaProcessosCobrados,
    DatePicker,
  },
  data() {
    return {
      isLoading: true,
      usuarioLogado: new UsuarioModel(),
      processosCobrados: [],
      tipoAplicacaoItems: TIPO_APLICACAO.TIPO_APLICACAO_ITEMS,
      statusProcessoItems: STATUS_PROCESSO.STATUS_PROCESSO_ITEMS,
      tipoAplicacaoFiltroString: null,
      statusProcessoFiltroString: null,
      statusProcessoFiltro: null,
      tipoAplicacaoFiltro: null,
      dataInicial: null,
      dataFinal: null,
      modoVisualizacao: false
    };
  },
  async mounted() {
    this.obterUsuarioStorage();
    await this.obterProcessosFiltrados();
    await this.filtrarProcessosPorQueryString();
  },
  methods: {
    obterUsuarioStorage() {
      this.usuarioLogado = JSON.parse(utilsStorage.obterUsuarioAtualStorage());

      if (this.usuarioLogado.perfil.id === PERFIL_USUARIO.BOARD.ID) {
        this.modoVisualizacao = true;
      }
    },

    async obterProcessosFiltrados(
      dataInicial,
      dataFinal,
      statusProcesso,
      tipoDeAplicacao
    ) {
      let setores = "";
      this.usuarioLogado.setoresUsuario.forEach(
        (s) => (setores += `setores=${s.id}&`)
      );
      this.processosCobrados = [];

      try {
        const response = await apiProcessoCobrado.obterProcessosFiltrados(
          setores,
          this.usuarioLogado.perfil.id,
          dataInicial,
          dataFinal,
          statusProcesso,
          tipoDeAplicacao
        );
        response.data.map((p) => {
          this.processosCobrados.push(new ProcessoCobradoModel(p));
        });
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }
    },

    capitalizarPrimeiraLetra(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    definirPeriodoInicialEfinalDoMes() {
      let indexMes;

      MES_ITEMS.forEach((m, i) => {
        if (m.toLowerCase() === this.$route.query.mes) indexMes = i;
      });

      let dataDoIndexMes = new Date(new Date(new Date().setMonth(indexMes)));

      if (indexMes > new Date().getMonth()) {
        this.dataFinal = new Date(
          dataDoIndexMes.getFullYear() - 1,
          dataDoIndexMes.getMonth() + 1,
          0
        ).toString();

        this.dataInicial = new Date(
          dataDoIndexMes.getFullYear() - 1,
          dataDoIndexMes.getMonth(),
          1
        ).toString();
      } else {
        this.dataFinal = new Date(
          dataDoIndexMes.getFullYear(),
          dataDoIndexMes.getMonth() + 1,
          0
        ).toString();

        this.dataInicial = new Date(
          new Date(new Date(new Date().setMonth(indexMes)).setDate(1))
        ).toString();
      }
    },

    async filtrarProcessosPorQueryString() {
      this.isLoading = true;
      
      if (this.$route.query.status) {
        this.dataInicial = this.$route.query.dataInicial;
        this.dataFinal = this.$route.query.dataFinal;

        this.statusProcessoFiltro =
          STATUS_PROCESSO[this.$route.query.status.toUpperCase()].ID;
        this.statusProcessoFiltroString =
          STATUS_PROCESSO[this.$route.query.status.toUpperCase()].VALUE;
      } else if (this.$route.query.tipo && this.$route.query.mes) {
        this.statusProcessoFiltro = STATUS_PROCESSO.APROVADO.ID;
        this.statusProcessoFiltroString = STATUS_PROCESSO.APROVADO.VALUE;

        this.tipoAplicacaoFiltro = this.$route.query.tipo;
        this.tipoAplicacaoFiltroString = this.capitalizarPrimeiraLetra(
          this.$route.query.tipo
        );

        this.definirPeriodoInicialEfinalDoMes();
      } else if (this.$route.query.tipo) {
        this.dataInicial = this.$route.query.dataInicial;
        this.dataFinal = this.$route.query.dataFinal;

        this.statusProcessoFiltro = STATUS_PROCESSO.APROVADO.ID;
        this.statusProcessoFiltroString = STATUS_PROCESSO.APROVADO.VALUE;

        this.tipoAplicacaoFiltro = this.$route.query.tipo;
        this.tipoAplicacaoFiltroString = this.capitalizarPrimeiraLetra(
          this.$route.query.tipo
        );
      }
      await this.obterProcessosFiltrados(
        this.dataInicial,
        this.dataFinal,
        this.statusProcessoFiltro,
        this.tipoAplicacaoFiltro
      );
      this.isLoading = false;
    },

    async filtrarProcessos() {
      this.tipoAplicacaoFiltro = null;
      this.statusProcessoFiltro = null;

      if (this.statusProcessoFiltroString !== null) {
        this.statusProcessoFiltro =
          STATUS_PROCESSO[this.statusProcessoFiltroString.toUpperCase()].ID;
      }

      if (this.tipoAplicacaoFiltroString !== null) {
        this.tipoAplicacaoFiltro =
          TIPO_APLICACAO[this.tipoAplicacaoFiltroString.toUpperCase()].ID;
      }

      await this.obterProcessosFiltrados(
        this.dataInicial,
        this.dataFinal,
        this.statusProcessoFiltro,
        this.tipoAplicacaoFiltro
      );
    },

    navegarNovoProcesso() {
      this.$router.push({
        name: "NovoProcesso",
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.filtro {
  display: flex;
  height: 4rem;
}
</style>
