import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  lang: {
    locales: { pt },
    current: "pt"
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#303F9F",
        secondary: "#3F51B5",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#ff8f00",
        gray: "#bbb"
      },
      dark: {
        primary: "#3F51B5",
        secondary: "#3F51B5",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#ff8f00",
        gray: "#bbb"
      }
    }
  }
});

//cor primaria #1543B7
//cor secundaria #2562FF
