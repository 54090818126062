var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.colunas,"items":_vm.valoresHomemHora,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.programador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatarDinheiro")(item.programador))+" ")]}},{key:"item.qualidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatarDinheiro")(item.qualidade))+" ")]}},{key:"item.suporte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatarDinheiro")(item.suporte))+" ")]}},{key:"item.comercial",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatarDinheiro")(item.comercial))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }