<template>
  <v-container fluid class="pa-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-divider></v-divider>
      <v-col cols="12">
        <h2>Controle de usuários</h2>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex align-center" style="height: 118px" v-if="usuarioLogado.eAdmin()">
      <v-col cols="12" md="2" class="mb-2">
        <v-btn
          @click="manipularModais(acaoModalCadastrar)"
          class="btn-primary elevation-1 ma-0"
          text
          >Adicionar</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="elevation-3">
          <v-card-title v-show="!isLoading">
            <v-spacer />
            <v-spacer />
            <v-col cols="12" md="4">
              <v-text-field
                v-model="pesquisar"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table 
            v-show="isLoading"
            item-key="name"
            class="elevation-1"
            loading
            loading-text="Buscando usuários..."
          ></v-data-table>        
          
          <v-data-table
            v-show="!isLoading"
            :headers="colunas"
            :items="usuarios"
            :search="pesquisar"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.actions="{ item }" v-if="usuarioLogado.eAdmin()">
              
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="primary"
                    class="mr-1"
                    @click="manipularModais(acaoModalAtualizar, item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top v-if="item.status===0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="red"
                    class="mr-1"
                    @click="manipularModais(acaoModalInativar, item)"
                  >
                    mdi-cancel
                  </v-icon>
                </template>
                <span>Inativar</span>
              </v-tooltip>

              <v-tooltip top v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="green"
                    class="mr-1"
                    @click="manipularModais(acaoModalReativar, item)"
                  >
                    mdi-restore
                  </v-icon>
                </template>
                <span>Reativar</span>
              </v-tooltip>

            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.tipoStatusString="{ item }">
              <v-chip
                :color="item.status ? 'red' : 'green'"
                text-color="white"
                small
                class="ma-2 chip"
                >{{ item.tipoStatusString }}</v-chip
              >
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.setoresUsuario="{ item }">
              <span v-for="item in item.setoresUsuario" :key="item.id">
                <v-chip small class="mr-2">{{ item.descricao }}</v-chip>
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="manipuladorAberturaModal" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="titulo-modal">
            {{ definirModoEdicaoModal ? "Editar" : "Adicionar" }} usuário
          </span>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-row class="mt-1">
              <v-col cols="12" md="4">
                <v-text-field
                  label="Login"
                  placeholder="exemplo.dsn.erp"
                  v-model="usuario.login"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  label="Email"
                  placeholder="exemplo.dsn.erp@alterdata.com.br"
                  v-model="usuario.email"
                ></v-text-field>
              </v-col>

              <v-col xs12 md="4" d-flex>
                <v-autocomplete
                  :items="perfilItems"
                  label="Perfil"
                  v-model="usuario.perfilString"
                ></v-autocomplete>
              </v-col>

              <v-col xs12 md="8" d-flex>
                <v-autocomplete
                  height="32"
                  small-chips
                  multiple
                  :items="setorItems"
                  label="Setor"
                  v-model="setores"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-primary elevation-1"
            text
            small
            @click="definirRequisicaoModal"
          >
            Salvar
          </v-btn>

          <v-btn
            class="btn-tertiary elevation-1"
            text
            small
            @click="fecharModal"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mensagemInativar" persistent max-width="400">
      <v-card class="card">
        <v-card-title> </v-card-title>
        <p class="titulo-modal text">Deseja inativar este usuário?</p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-primary elevation-1" small text @click="atualizarStatusDoUsuario">
            Confirmar
          </v-btn>
          <v-btn
            class="btn-tertiary elevation-1"
            small
            text
            @click="fecharModal"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mensagemAtivar" persistent max-width="400">
      <v-card class="card">
        <v-card-title> </v-card-title>
        <p class="titulo-modal text">Deseja reativar este usuário?</p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-primary elevation-1" small text @click="atualizarStatusDoUsuario">
            Confirmar
          </v-btn>
          <v-btn
            class="btn-tertiary elevation-1"
            small
            text
            @click="fecharModal"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-container>
</template>

<script>
import mixinMensagem from "@/mixin/mixin-mensagem";
import UsuarioModel from "@/model/usuario-model.js";
import apiUsuario from "../../api/usuario/usuario-api";
import {
  ACAO_MODAL,
  PERFIL_USUARIO,
  SETOR,
  STATUS_USUARIO
} from "@/constants/geral-constants.js";
import utilsStorage from "@/utils/storage";

export default {
  name: "ControleDeUsuarios",
  mixins: [mixinMensagem],
  data() {
    return {
      isLoading: true,
      usuario: new UsuarioModel(),
      usuarioLogado: new UsuarioModel(),
      usuarios: [],
      pesquisar: "",
      acaoModalAtualizar: ACAO_MODAL.ATUALIZAR.ID,
      acaoModalCadastrar: ACAO_MODAL.CADASTRAR.ID,
      acaoModalInativar: ACAO_MODAL.INATIVAR.ID,
      acaoModalReativar: ACAO_MODAL.REATIVAR.ID,
      perfilItems: PERFIL_USUARIO.PERFIL_ITEMS,
      setorItems: SETOR.SETOR_ITEMS,
      setores: [],
      definirModoEdicaoModal: false,
      manipuladorAberturaModal: false,
      mensagemInativar: false,
      mensagemAtivar: false,
      colunas: [
        {
          text: "Id",
          align: "start",
          value: "id"
        },
        { text: "Login", align: "left", value: "login" },
        { text: "E-mail", align: "left", value: "email" },
        { text: "Perfil", align: "center", value: "perfilString" },
        { text: "Setores", align: "left", value: "setoresUsuario" },
        {
          text: "Status",
          align: "center",
          value: "tipoStatusString",
          width: 0
        },
        { text: null, value: "actions", sortable: false },
      ]
    };
  },

  methods: {
    obterUsuarios() {

      const usuario = JSON.parse(utilsStorage.obterUsuarioAtualStorage());
      
      let setores = "";
      usuario.setoresUsuario.forEach((s) => (setores += `setores=${s.id}&`));

      apiUsuario
        .obterTodos(setores, usuario.perfil.id)
        .then(
          (response) => {
            this.usuarios = response.data.map((u) => new UsuarioModel(u))
            this.isLoading = false;
          }            
        )
        .catch((error) => {
          this.isLoading = false;
          this.dispararMensagem(
            error.response.data.message ||
              "Não foi possível se comunicar com o servidor!",
            "error"
          )
        }         
        );
    },

    manipularModais(acaoModal, item) {

      this.filtrarListaUsuariosDeAcordoComPerfilLogado();

      if (acaoModal === ACAO_MODAL.ATUALIZAR.ID) {
        this.usuario = new UsuarioModel(item);
        this.manipuladorAberturaModal = true;
        this.definirModoEdicaoModal = true;
        this.setores = this.usuario.setoresUsuario.map((s) => s.descricao);
      } 
      
      else if (acaoModal === ACAO_MODAL.CADASTRAR.ID) {
        this.manipuladorAberturaModal = true;
      } 
      
      else if (acaoModal === ACAO_MODAL.INATIVAR.ID) {
        this.usuario = new UsuarioModel(item);
        this.mensagemInativar = true;
      }

      else if (acaoModal === ACAO_MODAL.REATIVAR.ID) {
        this.usuario = new UsuarioModel(item);
        this.mensagemAtivar = true;
      }

    },

    filtrarListaUsuariosDeAcordoComPerfilLogado() {
      const usuario = JSON.parse(utilsStorage.obterUsuarioAtualStorage());
      const listaDeSetores = usuario.setoresUsuario.map((s) => s.descricao);
      if (usuario.perfil.id == PERFIL_USUARIO.MANAGER.ID) {
        this.perfilItems = PERFIL_USUARIO.PERFIL_ITEMS.filter((p) => {
          if (
            p !== PERFIL_USUARIO.ADMIN.VALUE &&
            p != PERFIL_USUARIO.BOARD.VALUE
          ) {
            return p;
          }
        });
        this.setorItems = listaDeSetores;
      }
    },

    fecharModal() {
      this.manipuladorAberturaModal = false;
      this.definirModoEdicaoModal = false;
      this.mensagemInativar = false;
      this.mensagemAtivar = false;
      this.setores = false;
      this.usuario = new UsuarioModel();
    },

    cadastrar() {
      this.definirDadosDaEntidade();

      apiUsuario
        .cadastrar(this.usuario)
        .then((response) => {
          this.usuarios.push(new UsuarioModel(response.data));
          this.usuario = new UsuarioModel();
          this.setores = false;
          this.dispararMensagem("Usuário cadastrado com sucesso.", "success");
        })
        .catch((error) => {
          this.dispararMensagem(
            error.response.data.message ||
              "Não foi possível se comunicar com o servidor!",
            "error"
          );
        });
    },

    atualizar() {
      this.definirDadosDaEntidade();

      apiUsuario
        .atualizar(this.usuario)
        .then((response) => {
          this.dispararMensagem(`Usuário alterado com sucesso.`, "success");
          this.atualizarUsuarioNaLista(new UsuarioModel(response.data));
          this.fecharModal();
        })
        .catch((error) => {
          this.dispararMensagem(
            error.response.data.message ||
              "Não foi possível se comunicar com o servidor!",
            "error"
          );
        });
    },

    atualizarStatusDoUsuario() {

      this.definirDadosDaEntidade();

      apiUsuario
        .atualizarStatusDoUsuario(this.usuario)
        .then((response) => {
          this.dispararMensagem(`Status do usuário alterado com sucesso.`, "success");
          this.atualizarUsuarioNaLista(new UsuarioModel(response.data));
          this.fecharModal();
        })
        .catch((error) => {
          this.dispararMensagem(
            error.response.data.message ||
              "Não foi possível se comunicar com o servidor!",
            "error"
          );
        });
    },

    atualizarUsuarioNaLista(usuario) {
      let indice = this.usuarios.findIndex((u) => u.id == usuario.id);
      if (indice < 0) return;

      this.usuarios.splice(indice, 1, usuario);
    },

    definirRequisicaoModal() {
      if (this.definirModoEdicaoModal) {
        this.atualizar();
      } else {
        this.cadastrar();
      }
    },

    definirDadosDaEntidade() {
      
      if (this.mensagemInativar) {
        this.usuario.status = STATUS_USUARIO.INATIVO.ID;
      } 

      else if(this.mensagemAtivar){
          this.usuario.status = STATUS_USUARIO.ATIVO.ID;
      }

      else {

        this.usuario.setoresUsuario = this.setores.map((s) => {
          return { id: SETOR[s.toUpperCase()].ID, descricao: s };
        });
      }

      this.usuario.idPerfil =
        PERFIL_USUARIO[this.usuario.perfilString.toUpperCase()].ID;
      this.usuario.login = this.usuario.login.toLowerCase();
      this.usuario.email = this.usuario.email.toLowerCase();

      if (!this.usuario.modeloValido()) {
        this.dispararMensagem("Todos os campos são obrigatórios.", "error");
        return;
      }
    },
  },
  mounted() {
    this.obterUsuarios();
    this.usuarioLogado  = new UsuarioModel(JSON.parse(utilsStorage.obterUsuarioAtualStorage()));
  }
};
</script>

<style lang="scss" scoped>
.card {
  padding: 1rem;
}

.text {
  margin-top: 5%;
  margin-bottom: 5%;
  text-align: center;
  font-size: 20px;
}

.titulo-modal {
  font-size: 18px;
  color: $cor_primaria;
}

.chip {
  width: 4.5rem;
  display: flex;
  justify-content: center;
}
</style>
