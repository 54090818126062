<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="colunas"
        :items="impostos"
        hide-default-header
        hide-default-footer
        class="elevation-2">
        <!-- eslint-disable-next-line -->
        <template v-slot:item.valor="{ item }">
          {{ item.valor | formatarPorcentagem }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.valorCalculadoImposto="{ item }">
          {{ obterTotalImposto(item) | formatarDinheiro }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { NOME_IMPOSTO } from "../../constants/geral-constants";

export default {
  name: "TabelaCustosOperacionais",
  props: {
    impostos: { type: Array, default: () => [] },
    custoTotalComHomemHora: { type: Number, default: 0 },
    valorMinimoCobranca: { type: Number, default: 0 }
  },
  filters: {
    formatarPorcentagem(valor) {
      return `${valor * 100} %`;
    },
    formatarDinheiro(valor) {
      return Number(valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    }
  },
  data() {
    return {
      colunas: [
        {
          text: "",
          sortable: false,
          align: "start",
          value: "descricao"
        },
        {
          text: "Custos Operacionais sobre valor real cobrado do cliente",
          sortable: false,
          align: "end",
          value: "valor"
        },
        {
          text: "",
          sortable: false,
          align: "end",
          value: "valorCalculadoImposto"
        }
      ]
    };
  },
  methods: {
    obterTotalImposto(item) {
      return item.descricao === NOME_IMPOSTO.CUSTOSADMINISTRATIVOS.VALUE
        ? item.calcularImpostoDeCustosAdministrativos(
          this.custoTotalComHomemHora
        )
        : item.calcularImpostos(this.valorMinimoCobranca);
    }
  }
};
</script>
