import api from "../api";

function atualizarListaValorPorSetor(valoresPorSetor) {
  return new Promise((resolve, reject) => {
    return api
      .put(`/api/valoresporsetor/atualizar`, valoresPorSetor)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterValorPorSetorPorIdSetor(idSetor) {
  return new Promise((resolve, reject) => {
    return api
      .get(`/api/valoresporsetor/obter/${idSetor}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterTodosValoresPorIdSetor(idSetor) {
  return new Promise((resolve, reject) => {
    return api
      .get(`/api/valoresporsetor/obter/todos/${idSetor}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  atualizarListaValorPorSetor,
  obterValorPorSetorPorIdSetor,
  obterTodosValoresPorIdSetor
};
