/**
 * Classe de valores fixos de hora homem.
 */
export default class ValoresFixosHoraHomemModel {
  /**
   * Construtor da classe.
   * @param {Array} obj Objeto da requisição.
   */
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.fgts = obj.fgts;
    this.inss = obj.inss;
    this.planoOdontologico = obj.planoOdontologico;
    this.planoSaude = obj.planoSaude;
    this.vale = obj.vale;

    this.fgtsPorcetagem = 0;
    this.inssPorcetagem = 0;
  }

  converterDecimalEmPorcentagem() {
    this.fgtsPorcetagem = this.fgts * 100;
    this.inssPorcetagem = this.inss * 100;
  }

  converterPorcentagemEmDecimal() {
    this.fgts = this.fgtsPorcetagem / 100;
    this.inss = this.inssPorcetagem / 100;
  }
}

