import api from "../api";

function atualizarValoresFixosImpostos(id, valoresFixosImpostos) {
  valoresFixosImpostos = valoresFixosImpostos || {};

  return new Promise((resolve, reject) => {
    return api
      .put(`/api/valoresfixos/impostos/atualizar/${id}`, valoresFixosImpostos)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterUltimoRegistroValoresFixosImpostos() {
  return new Promise((resolve, reject) => {
    return api
      .get("/api/valoresfixos/impostos/obter/ultimo-registro")
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterTodosValoresFixosImpostos() {
  return new Promise((resolve, reject) => {
    return api
      .get("/api/valoresfixos/impostos/obter/todos")
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obter(id) {
  return new Promise((resolve, reject) => {
    return api
      .get(`/api/valoresfixos/impostos/obter/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  atualizarValoresFixosImpostos,
  obterUltimoRegistroValoresFixosImpostos,
  obter,
  obterTodosValoresFixosImpostos
};
