import api from "../api";
import formatarData from "../../utils/data";

function cadastrarProcessoCobrado(processoCobradoModel) {
  processoCobradoModel = processoCobradoModel || {};
  return new Promise((resolve, reject) => {
    return api
      .post("/api/processocobrado/adicionar", processoCobradoModel)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function editarProcessoCobrado(processoCobradoModel, id) {
  processoCobradoModel = processoCobradoModel || {};
  return new Promise((resolve, reject) => {
    return api
      .put(`/api/processocobrado/atualizar/${id}`, processoCobradoModel)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterProcessoCobradoPorId(id) {
  return new Promise((resolve, reject) => {
    return api
      .get(`/api/processocobrado/obter/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterProcessosFiltrados(
  setores,
  idPerfil,
  dataInicial = null,
  dataFinal = null,
  idStatus = null,
  tipoDeAplicacao = null
) {
  return new Promise((resolve, reject) => {
    return api
      .get(
        `/api/processocobrado/obter-com-filtro${construirQueryString(
          setores,
          idPerfil,
          dataInicial,
          dataFinal,
          idStatus,
          tipoDeAplicacao
        )}`
      )
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function construirQueryString(
  setores,
  idPerfil,
  dataInicial,
  dataFinal,
  idStatus,
  tipoDeAplicacao
) {
  let query = `?${setores}idPerfil=${idPerfil}`;

  if (dataInicial != null) {
    query += `&dataInicial=${formatarData.formatarDataEHoraIso(dataInicial)}`;
  }
  if (dataFinal != null) {
    query += `&dataFinal=${formatarData.formatarDataQueryString(dataFinal)}`;
  }
  if (idStatus != null) {
    query += `&idStatus=${idStatus}`;
  }
  if (tipoDeAplicacao != null) {
    query += `&tipoDeAplicacao=${tipoDeAplicacao}`;
  }

  return query;
}

export default {
  cadastrarProcessoCobrado,
  editarProcessoCobrado,
  obterProcessoCobradoPorId,
  obterProcessosFiltrados
};
