import { NOME_IMPOSTO } from "@/constants/geral-constants";
/**
 * Classe que modela os impostos e sabe calcular os mesmos.
 */
export default class ImpostoFixoModel {
  /**
   *  Construtor da classe CalculosModel que recebe o objeto com os impostos.
   * @param {Object} obj Objeto com os impostos cadastrados no banco de dados.
   */
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id || 0;
    this.pisFaturamento = obj.pisFaturamento || 0;
    this.cofinsSobreFaturamento = obj.cofinsSobreFaturamento || 0;
    this.issSobreFaturamento = obj.issSobreFaturamento || 0;
    this.inssSobreDesoneracao = obj.inssSobreDesoneracao || 0;
    this.custosAdministrativos = obj.custosAdministrativos || 0;
    this.comissionamentoComercial = obj.comissionamentoComercial || 0;
    this.comissionamentoComercialReal = obj.comissionamentoComercial || 0;
    this.irpjCsllAdicIrSobreLucro = obj.irpjCsllAdicIrSobreLucro || 0;

    this.impostos = this._obterListaDeImpostos();

    this.pisFaturamentoPorcentagem = 0;
    this.cofinsSobreFaturamentoPorcentagem = 0;
    this.issSobreFaturamentoPorcentagem = 0;
    this.inssSobreDesoneracaoPorcentagem = 0;
    this.custosAdministrativosPorcentagem = 0;
    this.comissionamentoComercialPorcentagem = 0;
    this.irpjCsllAdicIrSobreLucroPorcentagem = 0;
  }

  converterDecimalEmPorcentagem() {
    this.pisFaturamentoPorcentagem = this.pisFaturamento * 100;
    this.cofinsSobreFaturamentoPorcentagem = this.cofinsSobreFaturamento * 100;
    this.issSobreFaturamentoPorcentagem = this.issSobreFaturamento * 100;
    this.inssSobreDesoneracaoPorcentagem = this.inssSobreDesoneracao * 100;
    this.custosAdministrativosPorcentagem = this.custosAdministrativos * 100;
    this.comissionamentoComercialPorcentagem = this.comissionamentoComercial * 100;
    this.irpjCsllAdicIrSobreLucroPorcentagem = this.irpjCsllAdicIrSobreLucro * 100;
  }

  converterPorcentagemEmDecimal() {
    this.pisFaturamento = this.pisFaturamentoPorcentagem / 100;
    this.cofinsSobreFaturamento = this.cofinsSobreFaturamentoPorcentagem / 100;
    this.issSobreFaturamento = this.issSobreFaturamentoPorcentagem / 100;
    this.inssSobreDesoneracao = this.inssSobreDesoneracaoPorcentagem / 100;
    this.custosAdministrativos = this.custosAdministrativosPorcentagem / 100;
    this.comissionamentoComercial = this.comissionamentoComercialPorcentagem / 100;
    this.irpjCsllAdicIrSobreLucro = this.irpjCsllAdicIrSobreLucroPorcentagem / 100;
  }

  /**
   * Método que soma o valor calculado de todos os impostos.
   * @param {Number} custoTotalHomemHora Custo com total homem/hora necessário para realizar cálculo do imposto.
   * @param {Number} valorMinimoParaCobranca Valor minímo para cobrança, necessário para realizar cálculo do imposto.
   * @param {Array} horasTrabalhadas Horas trabalhadas para verificar se existe comissionamento comercial.
   * @returns Retorna o total de despesas que é a soma de todos os impostos calculados com o custo total com homem/hora.
   */
  calcularTotalDespesas(
    custoTotalHomemHora,
    valorMinimoParaCobranca,
    horasTrabalhadas
  ) {
    horasTrabalhadas = horasTrabalhadas || [];

    if (this._verificaSeExisteComissionamentoComercial(horasTrabalhadas) == 0) {
      this.impostos.find(
        i => i.descricao === NOME_IMPOSTO.COMISSIONAMENTOCOMERCIAL.VALUE
      ).valor = 0;
    }

    return parseFloat(
      (
        this.impostos.reduce((total, imposto) => {
          if (imposto.descricao === NOME_IMPOSTO.CUSTOSADMINISTRATIVOS.VALUE) {
            return (total += imposto.calcularImpostoDeCustosAdministrativos(
              custoTotalHomemHora
            ));
          }

          return parseFloat(
            (total += imposto.calcularImpostos(
              valorMinimoParaCobranca
            )).toFixed(2)
          );
        }, 0) + custoTotalHomemHora
      ).toFixed(2)
    );
  }

  /**
   * Método para calcular o IRPJ/CSLL/ADISC.IR Sobre o lucro.
   * @param {Number} valorMinimoParaCobranca Valor minímo para cobrança, necessário para realizar cálculo do imposto.
   * @param {Number} totalDespesas Total de despesas do processo cobrado.
   * @returns Retorna o valor de IRPJ/CSLL/ADISC.IR sobre o lucro.
   */
  calcularIrpjCsllAdicIrSobreLucro(valorMinimoParaCobranca, totalDespesas) {
    return parseFloat(
      (
        (valorMinimoParaCobranca - totalDespesas) *
        this.irpjCsllAdicIrSobreLucro
      ).toFixed(2)
    );
  }

  /**
   * Método para verificar se existem horas trabalhadas de comercial.
   * @param {Object} horasTrabalhadas Objeto que contém todas horas trabalhadas de comercial.
   * @returns Retorna a soma do total de horas trabalhadas do comercial.
   */
  _verificaSeExisteComissionamentoComercial(horasTrabalhadas) {
    return horasTrabalhadas.reduce((total, item) => {
      total +=
        item.horaNormal +
        item.horaExtraCinquentaPorcento +
        item.horaExtraCemPorcento;

      return total;
    }, 0);
  }

  /**
   * Modela os impostos e cria um array para renderizar na tabela de custos operacionais.
   * @returns
   */
  _obterListaDeImpostos() {
    let impostos = [];

    impostos.push(
      new ImpostoModel(NOME_IMPOSTO.PISSFATURAMENTO.VALUE, this.pisFaturamento)
    );
    impostos.push(
      new ImpostoModel(
        NOME_IMPOSTO.CONFISSOBREFATURAMENTO.VALUE,
        this.cofinsSobreFaturamento
      )
    );
    impostos.push(
      new ImpostoModel(
        NOME_IMPOSTO.ISSSOBREFATURAMENTO.VALUE,
        this.issSobreFaturamento
      )
    );
    impostos.push(
      new ImpostoModel(
        NOME_IMPOSTO.INSSSOBREDESONERACAO.VALUE,
        this.inssSobreDesoneracao
      )
    );
    impostos.push(
      new ImpostoModel(
        NOME_IMPOSTO.CUSTOSADMINISTRATIVOS.VALUE,
        this.custosAdministrativos
      )
    );
    impostos.push(
      new ImpostoModel(
        NOME_IMPOSTO.COMISSIONAMENTOCOMERCIAL.VALUE,
        this.comissionamentoComercial
      )
    );

    return impostos;
  }
}

/**
 * Classe de modelo de um imposto.
 */
class ImpostoModel {
  /**
   * Construtor da classe ImpostoModel.
   * @param {String} descricao Descrição do imposto.
   * @param {Number} valor Valor do imposto.
   */
  constructor(descricao, valor) {
    this.descricao = descricao;
    this.valor = valor || 0;
  }

  /**
   * Método para cálculo do imposto de custos administrativos.
   * @param {Number} custoTotalComHomemHora Custo total com homem/hora de um processo.
   * @returns {Number} Valor do cálculo do imposto de custos administrativos.
   */
  calcularImpostoDeCustosAdministrativos(custoTotalComHomemHora) {
    return parseFloat((this.valor * custoTotalComHomemHora || 0).toFixed(2));
  }

  /**
   * Método para calcular os demais impostos.
   * @param {Number} valorMinimoParaCobranca Valor minímo para a cobrança de um processo.
   * @returns Valor do cálculo de imposto de acordo com sua alíquota de cada imposto.
   */
  calcularImpostos(valorMinimoParaCobranca) {
    return parseFloat((this.valor * valorMinimoParaCobranca || 0).toFixed(2));
  }
}
