<template>
  <v-data-table
    :headers="colunas"
    :items="valoresHomemHora"
    hide-default-footer
    class="elevation-1">
    <!-- eslint-disable-next-line -->
    <template v-slot:item.programador="{ item }">
      {{ item.programador | formatarDinheiro }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.qualidade="{ item }">
      {{ item.qualidade | formatarDinheiro }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.suporte="{ item }">
      {{ item.suporte | formatarDinheiro }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.comercial="{ item }">
      {{ item.comercial | formatarDinheiro }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "TabelaValorHoraHomem",
  props: {
    valoresHomemHora: { type: Array, default: () => [] },
  },
  filters: {
    formatarDinheiro(valor) {
      valor = valor || 0;
      return Number(valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  data() {
    return {
      colunas: [
        {
          text: "Programação",
          align: "end",
          sortable: false,
          value: "programador",
        },
        {
          text: "Qualidade",
          align: "end",
          sortable: false,
          value: "qualidade",
        },
        {
          text: "Suporte",
          align: "end",
          sortable: false,
          value: "suporte"
        },
        {
          text: "Comercial",
          align: "end",
          sortable: false,
          value: "comercial",
        },
      ],
    };
  },
};
</script>
