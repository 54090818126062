/**
 * Classe de Valor hora homem.
 */
export default class ValorHoraHomemModel {
  /**
   * Construtor da classe de valor hora homem.
   * @param {Array} obj Contém um objeto os dados de cada usuário.
   */
  constructor(obj) {
    obj = obj || {};

    this.programador = obj.programador || 0;
    this.qualidade = obj.qualidade || 0;
    this.suporte = obj.suporte || 0;
    this.comercial = obj.comercial || 0;

    this.valoresHomemHora = this._converterObjetoValorHoraHomemEmArray(obj);
  }

  _converterObjetoValorHoraHomemEmArray(obj) {
    let valores = [];

    valores.push(
      new ValorHoraHomem(
        obj.programador,
        obj.qualidade,
        obj.suporte,
        obj.comercial
      )
    );

    return valores;
  }
}
/**
 * Classe de Valor hora homem.
 */
class ValorHoraHomem {
  /**
   * onstrutor da classe de valor hora homem.
   * @param {Number} programador
   * @param {Number} qualidade
   * @param {Number} suporte
   * @param {Number} comercial
   */
  constructor(programador, qualidade, suporte, comercial) {
    this.programador = programador || 0;
    this.qualidade = qualidade || 0;
    this.suporte = suporte || 0;
    this.comercial = comercial || 0;
  }
}
