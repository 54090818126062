import { FUNCAO } from "./geral-constants";

const BIMER = {
  PROGRAMADOR: {
    funcao: FUNCAO.PROGRAMADOR.VALUE,
    horaNormal: 20,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  QUALIDADE: {
    funcao: FUNCAO.QUALIDADE.VALUE,
    horaNormal: 10,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  COMERCIAL: {
    funcao: FUNCAO.COMERCIAL.VALUE,
    horaNormal: 8,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  SUPORTE: {
    funcao: FUNCAO.SUPORTE.VALUE,
    horaNormal: 8,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  }
};

const MODA = {
  PROGRAMADOR: {
    funcao: FUNCAO.PROGRAMADOR.VALUE,
    horaNormal: 20,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  QUALIDADE: {
    funcao: FUNCAO.QUALIDADE.VALUE,
    horaNormal: 10,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },

  COMERCIAL: {
    funcao: FUNCAO.COMERCIAL.VALUE,
    horaNormal: 8,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  SUPORTE: {
    funcao: FUNCAO.SUPORTE.VALUE,
    horaNormal: 8,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  }
};

const IMMOBILE = {
  PROGRAMADOR: {
    funcao: FUNCAO.PROGRAMADOR.VALUE,
    horaNormal: 25,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  QUALIDADE: {
    funcao: FUNCAO.QUALIDADE.VALUE,
    horaNormal: 20,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  COMERCIAL: {
    funcao: FUNCAO.COMERCIAL.VALUE,
    horaNormal: 0,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  SUPORTE: {
    funcao: FUNCAO.SUPORTE.VALUE,
    horaNormal: 8,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  }
};

const PACK = {
  PROGRAMADOR: {
    funcao: FUNCAO.PROGRAMADOR.VALUE,
    horaNormal: 25,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  QUALIDADE: {
    funcao: FUNCAO.QUALIDADE.VALUE,
    horaNormal: 20,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  COMERCIAL: {
    funcao: FUNCAO.COMERCIAL.VALUE,
    horaNormal: 0,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  SUPORTE: {
    funcao: FUNCAO.SUPORTE.VALUE,
    horaNormal: 8,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  }
};

const SHOP = {
  PROGRAMADOR: {
    funcao: FUNCAO.PROGRAMADOR.VALUE,
    horaNormal: 0,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  QUALIDADE: {
    funcao: FUNCAO.QUALIDADE.VALUE,
    horaNormal: 0,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  COMERCIAL: {
    funcao: FUNCAO.COMERCIAL.VALUE,
    horaNormal: 0,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  },
  SUPORTE: {
    funcao: FUNCAO.SUPORTE.VALUE,
    horaNormal: 8,
    horaExtraCinquentaPorcento: 0,
    horaExtraCemPorcento: 0
  }
};

export { BIMER, MODA, IMMOBILE, PACK, SHOP };
