import api from "../api";

function atualizarValoresFixosHoraHomem(id, valoresFixosHoraHomem) {
  valoresFixosHoraHomem = valoresFixosHoraHomem || {};

  return new Promise((resolve, reject) => {
    return api
      .put(`/api/valoresfixos/horahomem/atualizar/${id}`, valoresFixosHoraHomem)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterUltimoRegistroValoresFixosHoraHomem() {
  return new Promise((resolve, reject) => {
    return api
      .get("/api/valoresfixos/horahomem/obter/ultimo-registro")
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterTodosValoresFixosHoraHomem() {
  return new Promise((resolve, reject) => {
    return api
      .get("/api/valoresfixos/horahomem/obter/todos")
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obter(id) {
  return new Promise((resolve, reject) => {
    return api
      .get(`/api/valoresfixos/horahomem/obter/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  obterUltimoRegistroValoresFixosHoraHomem,
  obterTodosValoresFixosHoraHomem,
  obter,
  atualizarValoresFixosHoraHomem,
};
