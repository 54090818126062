<template>
  <v-container fluid>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-card :loading="loading" class="box-login" elevation="10">
        <div class="logo">Gestor de Processos Cobrados</div>

        <form class="form-login" autocomplete="off">
          <v-text-field
            outlined
            dense
            color="primary"
            label="Login"
            v-model="usuario.login"
          ></v-text-field>

          <v-text-field
            @keyup.enter="logar"
            outlined
            dense
            label="Senha"
            v-model="usuario.senha"
            :append-icon="value ? 'visibility_off' : 'visibility'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
          ></v-text-field>

          <v-btn
            color="primary"
            @click="logar"
            type="button"
            class="btn-entrar"
          >
            Entrar
          </v-btn>
        </form>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import utilsStorage from "@/utils/storage";
import mixinMensagen from "@/mixin/mixin-mensagem";
import UsuarioModel from "@/model/usuario-model";
import apiUsuario from "@/api/usuario/usuario-api";

export default {
  name: "login",
  mixins: [mixinMensagen],
  data() {
    return {
      loading: false,
      usuario: new UsuarioModel(),
      value: "password"
    };
  },

  methods: {
    logar() {
      if (!this.usuario.login || !this.usuario.senha) {
        this.dispararMensagem("Informe o e-mail e senha para acesso.", "error");
        return;
      }

      this.loading = true;
      apiUsuario
        .logar(this.usuario)
        .then(response => {
          this.loading = false;
          let usuario = new UsuarioModel(response.data);
          this.salvarInformacoesLocalStorage(
            JSON.stringify(usuario),
            response.data.token
          );
          this.$router.push({ name: "Processos" });
        })
        .catch(error => {
          this.dispararMensagem(
            error.response.data.message ||
              "Não foi possível se comunicar com o servidor!",
            "error"
          );
          utilsStorage.removerAutenticacao();
          this.loading = false;
        });
    },

    salvarInformacoesLocalStorage(usuario, token) {
      utilsStorage.salvarUsuarioNaStorage(usuario);
      utilsStorage.salvarTokenNaStorage(token);
    }
  }
};
</script>
<style lang="scss" scoped>
.box-login {
  background-color: $white;
  width: 22rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
  padding: 2rem;
  border-radius: 4px;
}
.logo {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: #303f9f;
}
.btn-entrar {
  width: 100%;
  border: 0px;
  padding: 1rem;
  font-weight: 600;
  border: 1px solid #ddd;
  margin: 0;
  margin-top: 1rem;
}
.btn-entrar:hover {
  cursor: pointer;
  background-color: rgb(217, 223, 217);
}
.btn-entrar:focus {
  outline: none;
}
div {
  width: 100%;
}
</style>
