/**
 * Classe de Horas trabalhadas.
 */
export default class HorasTrabalhadasModel {
  /**
   *  Construtor da classe horas trabalhadas que recebe o objeto.
   * @param {Object} obj Objeto com as horas trabalhadas cadastradas no banco de dados.
   */
  constructor(obj) {
    obj = obj || {};

    this.funcao = obj.funcao;
    this.horaNormal = obj.horaNormal || 0;
    this.horaExtraCinquentaPorcento = obj.horaExtraCinquentaPorcento || 0;
    this.horaExtraCemPorcento = obj.horaExtraCemPorcento || 0;
  }
}
