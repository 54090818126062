<template>
  <v-container fluid class="pa-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row class="pa-0">
      <v-col cols="12">
        <h2>Configurações</h2>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="my-2">
      <v-col cols="12" md="12">
        <h2 class="titulo">Valores por setor</h2>
      </v-col>
    </v-row>

    <v-card class="pa-5 elevation-1.5 card-configuracoes">
      <v-container v-show="isLoadingValorSetor" class="loading-card">
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-container>
      <v-col cols="12" class="pa-0 ma-0" v-show="!isLoadingValorSetor">
        <v-row>
          <v-col cols="12" md="12">
            <v-autocomplete
              label="Linha de produto"
              auto-select-first
              v-model="setorString"
              @change="obterValoresPorSetor"
              :items="setorItems"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col>
                <h3 class="subtitulo mr-2">Programação:</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <CampoDinheiro
                  class="tipo-numero mr-5"
                  label="Salario base"
                  type="number"
                  v-model="programacao.salarioBase"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  class="tipo-numero mr-5"
                  label="Total de horas no mês"
                  type="number"
                  v-model="programacao.totalDeHorasNoMes"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row>
              <v-col>
                <h3 class="subtitulo mr-2">Qualidade:</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <CampoDinheiro
                  class="tipo-numero mr-5"
                  label="Salario base"
                  type="number"
                  v-model="qualidade.salarioBase"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  class="tipo-numero mr-5"
                  label="Total de horas no mês"
                  type="number"
                  v-model="qualidade.totalDeHorasNoMes"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col>
                <h3 class="subtitulo mr-2">Suporte:</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <CampoDinheiro
                  class="tipo-numero mr-5"
                  label="Salario base"
                  type="number"
                  v-model="suporte.salarioBase"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  class="tipo-numero mr-5"
                  label="Total de horas no mês"
                  type="number"
                  v-model="suporte.totalDeHorasNoMes"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row>
              <v-col>
                <h3 class="subtitulo mr-2">Comercial:</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <CampoDinheiro
                  class="tipo-numero mr-5"
                  label="Salario base"
                  type="number"
                  :disabled="controlarCamposComercial()"
                  v-model="comercial.salarioBase"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  class="tipo-numero mr-5"
                  label="Total de horas no mês"
                  type="number"
                  :disabled="controlarCamposComercial()"
                  :hint="controlarCamposComercial() ? 'Linha de produto não considera comercial' : ''"
                  persistent-hint
                  v-model="comercial.totalDeHorasNoMes"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mr-2" justify="end">
          <v-btn
            class="btn-tertiary elevation-1"
            text
            small
            disabled
            v-show="isUpdatingValorSetor"
          >
            <v-progress-circular
              indeterminate
              color="gray"
              size="20"
              width="3"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            class="btn-tertiary elevation-1"
            text
            small
            @click="controlarModal(atualizarValoresPorSetor)"
            v-show="!isUpdatingValorSetor"
          >
            Atualizar
          </v-btn>
        </v-row>
      </v-col>
    </v-card>

    <v-row class="my-2">
      <v-col cols="12" md="12">
        <h2 class="titulo">Valores fixos hora-homem</h2>
      </v-col>
    </v-row>

    <v-card class="pa-5 elevation-1.5 card-configuracoes">
      <v-container v-show="isLoadingValorHora" class="loading-card">
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-container>
      <v-col cols="12" class="pa-0 ma-0" v-show="!isLoadingValorHora">
        <v-row>
          <v-col cols="12" md="2">
            <CampoNumero
              class="tipo-numero mr-5"
              label="FGTS"
              sufixo="%"
              v-model="valoresFixosHomemHoraModel.fgtsPorcetagem"
            >
            </CampoNumero>
          </v-col>

          <v-col cols="12" md="2">
            <CampoNumero
              class="tipo-numero mr-5"
              label="INSS"
              sufixo="%"
              v-model="valoresFixosHomemHoraModel.inssPorcetagem"
            >
            </CampoNumero>
          </v-col>

          <v-col cols="12" md="2">
            <CampoDinheiro
              class="tipo-numero mr-5"
              label="Plano odontológico"
              type="number"
              v-model="valoresFixosHomemHoraModel.planoOdontologico"
            />
          </v-col>

          <v-col cols="12" md="3">
            <CampoDinheiro
              class="tipo-numero mr-5"
              label="Plano saúde"
              type="number"
              v-model="valoresFixosHomemHoraModel.planoSaude"
            />
          </v-col>

          <v-col cols="12" md="3">
            <CampoDinheiro
              class="tipo-numero mr-5"
              label="Vale alimentação"
              type="number"
              v-model="valoresFixosHomemHoraModel.vale"
            />
          </v-col>
        </v-row>

        <v-row> </v-row>

        <v-row class="mr-2" justify="end">
          <v-btn
            class="btn-tertiary elevation-1"
            text
            small
            disabled
            v-show="isUpdatingValorHora"
          >
            <v-progress-circular
              indeterminate
              color="gray"
              size="20"
              width="3"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            class="btn-tertiary elevation-1"
            text
            small
            @click="controlarModal(atualizarValoresFixosHoraHomem)"
            v-show="!isUpdatingValorHora"
          >
            Atualizar
          </v-btn>
        </v-row>
      </v-col>
    </v-card>

    <v-row class="my-2 mt-6">
      <v-col cols="12" md="12">
        <h2 class="titulo">Valores fixos impostos</h2>
      </v-col>
    </v-row>

    <v-card class="pa-5 elevation-1.5 card-configuracoes">
      <v-container v-show="isLoadingValorImposto" class="loading-card">
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-container>
      <v-col cols="12" class="pa-0 ma-0" v-show="!isLoadingValorImposto">
        <v-row>
          <v-col cols="12" md="3">
            <CampoNumero
              class="tipo-numero mr-5"
              label="Pis faturamento"
              type="number"
              sufixo="%"
              v-model="valoresFixosImpostoModel.pisFaturamentoPorcentagem"
            />
          </v-col>

          <v-col cols="12" md="3">
            <CampoNumero
              class="tipo-numero mr-5"
              label="COFINS sobre faturamento"
              type="number"
              sufixo="%"
              v-model="
                valoresFixosImpostoModel.cofinsSobreFaturamentoPorcentagem
              "
            />
          </v-col>

          <v-col cols="12" md="3">
            <CampoNumero
              class="tipo-numero mr-5"
              label="ISS sobre faturamento"
              v-model="valoresFixosImpostoModel.issSobreFaturamentoPorcentagem"
              sufixo="%"
              type="number"
            />
          </v-col>

          <v-col cols="12" md="3">
            <CampoNumero
              class="tipo-numero mr-5"
              label="INSS sobre desoneração"
              type="number"
              sufixo="%"
              v-model="valoresFixosImpostoModel.inssSobreDesoneracaoPorcentagem"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <CampoNumero
              class="tipo-numero mr-5"
              label="Custos administrativos"
              type="number"
              sufixo="%"
              v-model="
                valoresFixosImpostoModel.custosAdministrativosPorcentagem
              "
            />
          </v-col>

          <v-col cols="12" md="4">
            <CampoNumero
              class="tipo-numero mr-5"
              label="Comissionamento comercial"
              type="number"
              sufixo="%"
              v-model="
                valoresFixosImpostoModel.comissionamentoComercialPorcentagem
              "
            />
          </v-col>

          <v-col cols="12" md="4">
            <CampoNumero
              class="tipo-numero mr-5"
              label="IRPJ/CSLL/ADIC IR sobre lucro"
              type="number"
              sufixo="%"
              v-model="
                valoresFixosImpostoModel.irpjCsllAdicIrSobreLucroPorcentagem
              "
            />
          </v-col>
        </v-row>

        <v-row class="mr-2" justify="end">
          <v-btn
            class="btn-tertiary elevation-1"
            text
            small
            disabled
            v-show="isUpdatingValorImposto"
          >
            <v-progress-circular
              indeterminate
              color="gray"
              size="20"
              width="3"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            class="btn-tertiary elevation-1"
            text
            small
            @click="controlarModal(atualizarValoresFixosImpostos)"
            v-show="!isUpdatingValorImposto"
          >
            Atualizar
          </v-btn>
        </v-row>
      </v-col>
    </v-card>

    <v-dialog v-model="manipuladorModal" persistent max-width="400">
      <v-card class="pa-2">
        <p class="texto-modal pa-4 text">{{isUpdating? "Atualizando informações, aguarde..." : "Confirma as alterações?"}}</p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-primary elevation-1"
            small
            text
            v-show="isUpdating"
          >
            <v-progress-circular
              indeterminate
              color="white"
              size="20"
              width="3"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            class="btn-primary elevation-1"
            small
            text
            @click="acaoModal"
            v-show="!isUpdating">
            Confirmar
          </v-btn>
          <v-btn
            class="btn-tertiary elevation-1"
            small
            text
            @click="controlarModal"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import mixinMensagen from "@/mixin/mixin-mensagem";
import { FUNCAO, SETOR } from "@/constants/geral-constants";

import apiValoresFixosHoraHomem from "@/api/valores-fixos-hora-homem/valores-fixos-hora-homem-api";
import apiValoresFixosImpostos from "@/api/valores-fixos-impostos/valores-fixos-impostos-api";
import apiValorPorSetor from "../../api/valor-por-setor/valor-por-setor-api";

import ValoresFixosImpostoModel from "@/model/imposto-fixo-model";
import ValoresFixosHoraHomemModel from "@/model/valores-fixos-hora-homem-model";
import ValorPorSetorModel from "../../model/valor-por-setor-model";

import CampoDinheiro from "../../components/comum/CampoDinheiro";
import CampoNumero from "../../components/comum/CampoNumero";

export default {
  name: "Configuracoes",
  components: {
    CampoDinheiro,
    CampoNumero,
  },
  mixins: [mixinMensagen],

  async mounted() {
    await this.obterValoresPorSetor();
    await this.obterUltimoRegistroValoresFixosHoraHomem();
    await this.obterUltimoRegistroValoresFixosImpostos();
  },

  data() {
    return {
      isLoadingValorSetor: true,
      isLoadingValorHora: true,
      isLoadingValorImposto: true,
      isUpdating: false,
      isUpdatingValorSetor: false,
      isUpdatingValorHora: false,
      isUpdatingValorImposto: false,
      setorItems: SETOR.SETOR_ITEMS,
      setorString: SETOR.SETOR_ITEMS.find((e) => e),

      valoresFixosHomemHoraModel: new ValoresFixosHoraHomemModel(),
      valoresFixosImpostoModel: new ValoresFixosImpostoModel(),
      valoresPorSetor: new Array(),
      programacao: new ValorPorSetorModel(),
      qualidade: new ValorPorSetorModel(),
      suporte: new ValorPorSetorModel(),
      comercial: new ValorPorSetorModel(),

      manipuladorModal: false,
      acaoModal: null,
    };
  },

  methods: {
    async obterUltimoRegistroValoresFixosHoraHomem() {
      try {
        const response =
          await apiValoresFixosHoraHomem.obterUltimoRegistroValoresFixosHoraHomem();
        this.valoresFixosHomemHoraModel = new ValoresFixosHoraHomemModel(
          response.data
        );
        this.valoresFixosHomemHoraModel.converterDecimalEmPorcentagem();
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }
      this.isLoadingValorHora = false;
    },

    async obterUltimoRegistroValoresFixosImpostos() {
      try {
        const response =
          await apiValoresFixosImpostos.obterUltimoRegistroValoresFixosImpostos();
        this.valoresFixosImpostoModel = new ValoresFixosImpostoModel(
          response.data
        );
        this.valoresFixosImpostoModel.converterDecimalEmPorcentagem();
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }
      this.isLoadingValorImposto = false;
    },

    async obterTodosValoresPorIdSetor(idSetor) {
      try {
        const response = await apiValorPorSetor.obterTodosValoresPorIdSetor(
          idSetor
        );
        this.valoresPorSetor = response.data.map(
          (element) => new ValorPorSetorModel(element)
        );
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }
      this.isLoadingValorSetor = false;
    },

    async obterValoresPorSetor() {
      await this.obterTodosValoresPorIdSetor(
        SETOR[this.setorString.toUpperCase()].ID
      );
      this.programacao = this.atualizarValorPorFuncao(FUNCAO.PROGRAMADOR.ID);
      this.qualidade = this.atualizarValorPorFuncao(FUNCAO.QUALIDADE.ID);
      this.suporte = this.atualizarValorPorFuncao(FUNCAO.SUPORTE.ID);
      this.comercial = this.atualizarValorPorFuncao(FUNCAO.COMERCIAL.ID);
    },

    atualizarValorPorFuncao(idFuncao) {
      return new ValorPorSetorModel(
        this.valoresPorSetor.find((e) => e.funcao === idFuncao)
      );
    },

    async atualizarValoresFixosHoraHomem() {
      this.isUpdating = true;
      try {
        this.valoresFixosHomemHoraModel.converterPorcentagemEmDecimal();

        await apiValoresFixosHoraHomem.atualizarValoresFixosHoraHomem(
          this.valoresFixosHomemHoraModel.id,
          this.valoresFixosHomemHoraModel
        );

        this.dispararMensagem(
          "Valores fixos impostos atualizado com sucesso.",
          "success"
        );
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }

      this.manipuladorModal = !this.manipuladorModal;
      this.isUpdating = false;
      this.isUpdatingValorHora = false;
    },

    async atualizarValoresFixosImpostos() {
      this.isUpdating = true;
      try {
        this.valoresFixosImpostoModel.converterPorcentagemEmDecimal();
        await apiValoresFixosImpostos.atualizarValoresFixosImpostos(
          this.valoresFixosImpostoModel.id,
          this.valoresFixosImpostoModel
        );

        this.dispararMensagem(
          "Valores fixos impostos atualizado com sucesso.",
          "success"
        );
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }

      this.manipuladorModal = !this.manipuladorModal;

      this.isUpdating = false;
      this.isUpdatingValorImposto = false;
    },

    async atualizarValoresPorSetor() {
      this.isUpdating = true;
      const requicao = [
        this.programacao,
        this.qualidade,
        this.suporte,
        this.comercial,
      ];

      try {
        await apiValorPorSetor.atualizarListaValorPorSetor(requicao);
        this.dispararMensagem(
          "Valores fixos impostos atualizado com sucesso.",
          "success"
        );
      } catch (error) {
        this.dispararMensagem(
          error.response.data.message ||
            "Não foi possível se comunicar com o servidor!",
          "error"
        );
      }

      this.manipuladorModal = !this.manipuladorModal;

      this.isUpdating = false;
      this.isUpdatingValorSetor = false;
    },

    controlarModal(funcao) {
      if(funcao.name == "bound atualizarValoresPorSetor"){
        this.isUpdatingValorSetor = true;
      }

      if(funcao.name == "bound atualizarValoresFixosImpostos"){
        this.isUpdatingValorImposto = true;
      }

      if(funcao.name == "bound atualizarValoresFixosHoraHomem"){
        this.isUpdatingValorHora = true;
      }

      this.manipuladorModal = !this.manipuladorModal;
      this.acaoModal = funcao;

      if(this.manipuladorModal == false){
        this.isUpdatingValorSetor = false;
        this.isUpdatingValorImposto = false;
        this.isUpdatingValorHora = false;
      }
    },

    controlarCamposComercial() {
      return !SETOR.SETOR_COM_COMERCIAL_ITEMS.includes(this.setorString);
    }
  },
};
</script>

<style lang="scss" scoped>
.texto-modal {
  font-size: 18px;
  color: $cor_primaria;
}

.card-configuracoes {
  min-height: 120px;
}

.loading-card {
  width: 100%;
  height: 120px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
