import { FUNCAO } from "@/constants/geral-constants";

/**
 * Classe com os cálculos de Custo total com homem hora e valor minímo para cobrança.
 */
export default class CalculosModel {
  /**
   * Construtor da classe CalculosModel que recebe as horas trabalhadas de todas funções que são necessárias nos cálculos da classe.
   * @param {Array} horasTrabalhadas Contém os objetos com as horas trabalhadas de cada função.
   */
  constructor(horasTrabalhadas) {
    horasTrabalhadas = horasTrabalhadas || [];

    this.horasTrabalhadas = horasTrabalhadas;
  }

  /**
   * Método que calcula o custo total com homem/hora de acordo com a quantidade horas.
   * @param {Object} valorHomemHora Recebe o valor homem/hora de acordo com cada função.
   * @returns {Number} Retorna o valor do custo total com homem/hora.
   */
  valorCustoTotalComHomemHora(valorHomemHora) {
    return parseFloat(this.horasTrabalhadas.reduce((total, item) => {
      if (FUNCAO[item.funcao.toUpperCase()]) {
        total += this._calculoCustoHomemHoraPorFuncao(item, valorHomemHora);
      }
      return total;
    }, 0).toFixed(2));
  }

  /**
   * Método para calcular o valo minímo para a cobrança de um processo cobrado.
   * @param {Object} impostoFixo Recebe o imposto fixo para calcular o comissionamneto comercial se houver e para calcular o custo total com homem hora.
   * @param {Object} valorHomemHora Recebe o valor homem/hora de acordo com cada função.
   * @returns {Number} Retorna o valor mínimo para cobrança de um processo cobrado.
   */
  valorMinimoParaCobranca(impostoFixo, valorHomemHora) {
    if (this._verificaSeExisteComissionamentoComercial() == 0) {
      impostoFixo.comissionamentoComercial = 0;
    }

    return parseFloat(
      this._calculoValorMinimoParaCobranca(
        this.valorCustoTotalComHomemHora(valorHomemHora),
        impostoFixo
      ).toFixed(2)
    );
  }

  /**
   * Método privado para verificar se exitem horas trabalhadas de comercial.
   * @returns {Number} Retorna o total de horas trabalhadas de comercial.
   */
  _verificaSeExisteComissionamentoComercial() {
    return this.horasTrabalhadas.reduce((total, item) => {
      if (item.funcao === FUNCAO.COMERCIAL.VALUE) {
        total +=
          item.horaNormal +
          item.horaExtraCinquentaPorcento +
          item.horaExtraCemPorcento;
      }

      return total;
    }, 0);
  }

  /**
   * Método privado para calcular o valor de custo com homem/hora de acordo com sua função.
   * @param {Object} item Objeto com as horas de cada função.
   * @param {Object} valorHomemHora Valor homem/hora.
   * @returns {Number} Retorna o valor do cálculo de custo homem/hora de todas as horas trabalhadas de acordo com sua função.
   */

  _calculoCustoHomemHoraPorFuncao(item, valorHomemHora) {
    return parseFloat(
      (
        item.horaNormal * valorHomemHora[item.funcao] +
        item.horaExtraCinquentaPorcento *
          (valorHomemHora[item.funcao] + valorHomemHora[item.funcao] / 2) +
        item.horaExtraCemPorcento * (2 * valorHomemHora[item.funcao])
      ).toFixed(2)
    );
  }

  /**
   * Método com o cálculo de um valor minímo para a cobrança de um processo.
   * @param {Number} custoTotalComHomemHora Custo total de hora/homem somando todas as horas trabalhadas por todas funções do processo.
   * @param {Object} impostoFixo Valores de impostos fixos necessários para o cálculo.
   * @returns {Number} Retorna o valor mínimo para cobrança de acordo com o cálculo da planilha de proceso cobrado.
   */

  _calculoValorMinimoParaCobranca(custoTotalComHomemHora, impostoFixo) {
    const baseCalculo = parseFloat(
      (
        custoTotalComHomemHora * impostoFixo.pisFaturamento +
        custoTotalComHomemHora * impostoFixo.cofinsSobreFaturamento +
        custoTotalComHomemHora * impostoFixo.issSobreFaturamento +
        custoTotalComHomemHora * impostoFixo.inssSobreDesoneracao +
        custoTotalComHomemHora * impostoFixo.custosAdministrativos
      ).toFixed(2)
    );

    return parseFloat(
      (
        ((baseCalculo + custoTotalComHomemHora) *
          impostoFixo.comissionamentoComercial +
          (baseCalculo + custoTotalComHomemHora)) *
          0.6 +
        ((baseCalculo + custoTotalComHomemHora) *
          impostoFixo.comissionamentoComercial +
          (baseCalculo + custoTotalComHomemHora))
      ).toFixed(2)
    );
  }
}
