<template>
  <v-container fluid class="pa-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="5"
      multi-line
      right
      shaped
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <h2>Dashboard</h2>
        <h3>
          Acompanhe os processos com alguns painéis abaixo, separados por
          categorias.
        </h3>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-end pa-0">
      <v-col cols="12" md="3">
        <v-autocomplete
          :items="tipoFiltroItems"
          outlined
          dense
          label="Período"
          v-model="periodoFiltro"
          @change="obterEPopularGraficos"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <div cols="12" v-show="isLoading" class="circulo-carregamento">
        <v-progress-circular
          :size="200"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <p>Carregando gráficos do dashboard...</p>
      </div>
      <v-col cols="12" md="4">
        <v-card v-show="!isLoading" elevation="6" id="grafico-por-status">
          <highcharts
            :options="graficoDemostrativoQuantidadeProcessosPorStatus"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card v-show="!isLoading" elevation="6" id="porcentagem">
          <highcharts
            :options="graficoDemostrativoPorcentagemDosUltimosMeses"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card v-show="!isLoading" elevation="6" height="400" id="quantidade">
          <highcharts
            :options="graficoDemostrativoQuantidadeProcessosPorStatus"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Highcharts from "highcharts";
import ProcessoCobradoModel from "@/model/processo-cobrado-model";
import apiProcessoCobrado from "@/api/processo-cobrado/processo-cobrado-api";
import utilsStorage from "@/utils/storage";
import utilsData from "@/utils/data";

import {
  MES_ITEMS,
  TIPO_APLICACAO,
  STATUS_PROCESSO,
  TIPO_FILTRO,
} from "@/constants/geral-constants";
import mixinMensagem from "@/mixin/mixin-mensagem";
export default {
  mixins: [mixinMensagem],
  name: "Dashboard",
  data() {
    return {
      isLoading: true,
      processoCobrado: new ProcessoCobradoModel(),
      processosCobrados: [],
      Highcharts: Highcharts,
      mes: new Date(),
      graficoDemostrativoPorUltimosMeses: new Object(),
      graficoDemostrativoPorcentagemDosUltimosMeses: new Object(),
      graficoDemostrativoQuantidadeProcessosPorStatus: new Object(),
      tipoFiltroItems: TIPO_FILTRO.TIPO_FILTRO_ITEMS,
      periodoFiltro: TIPO_FILTRO.TIPO_FILTRO_ITEMS[4],

      quantidadeProcessoPendente: 0,
      quantidadeProcessoAprovado: 0,
      quantidadeProcessoReprovado: 0,
      quantidadeProcessoCancelado: 0,

      quantidadeProcessoDesktop: 0,
      quantidadeProcessoMobile: 0,
      quantidadeProcessoWeb: 0,

      valorProcessoSegundoMesAnteriorWeb: 0,
      valorProcessoSegundoMesAnteriorMobile: 0,
      valorProcessoSegundoMesAnteriorDesktop: 0,

      valorProcessoMesAnteriorWeb: 0,
      valorProcessoMesAnteriorMobile: 0,
      valorProcessoMesAnteriorDesktop: 0,

      valorProcessoMesAtualWeb: 0,
      valorProcessoMesAtualMobile: 0,
      valorProcessoMesAtualDesktop: 0
    };
  },
  async mounted() {
    await this.obterValoresGraficos();

    this.atribuirValoresAoGraficoDePizzaDeQuantidadeDeProcessos();
    this.montarGraficoDemostrativoPorcentagemDosUltimosMeses();

    this.atribuirValoresAoGraficoDeBarras();
    this.montarGraficoDemostrativoPorUltimosMeses();

    this.atribuirValoresAoGraficoDePizzaDeStatusDeProcesso();
    this.montarGraficoDemostrativoQuantidadeProcessosPorStatus();
  },

  methods: {
    async obterEPopularGraficos() {
      await this.obterValoresGraficos();

      this.atribuirValoresAoGraficoDePizzaDeQuantidadeDeProcessos();
      this.montarGraficoDemostrativoPorcentagemDosUltimosMeses();

      this.atribuirValoresAoGraficoDeBarras();
      this.montarGraficoDemostrativoPorUltimosMeses();

      this.atribuirValoresAoGraficoDePizzaDeStatusDeProcesso();
      this.montarGraficoDemostrativoQuantidadeProcessosPorStatus();
    },
    async obterValoresGraficos() {
      const usuario = JSON.parse(utilsStorage.obterUsuarioAtualStorage());
      let setores = "";
      usuario.setoresUsuario.forEach((s) => (setores += `setores=${s.id}&`));
      try {
        this.processosCobrados = [];
        const response = await apiProcessoCobrado.obterProcessosFiltrados(
          setores,
          usuario.perfil.id,
          TIPO_FILTRO[
            this.periodoFiltro
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/\s/g, "")
          ].DATA_INICIAL,
          TIPO_FILTRO[
            this.periodoFiltro
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/\s/g, "")
          ].DATA_FINAL
        );

        response.data.forEach((p) =>
          this.processosCobrados.push(new ProcessoCobradoModel(p))
        );
      } catch {
        (error) => {
          this.dispararMensagem(
            error.response.data.message ||
              "Não foi possível se comunicar com o servidor!",
            "error"
          );
        };
      }
    },
    popularGraficoDeBarras(tipoDeAplicacao, mes) {
      return this.processosCobrados
        .filter(
          (p) =>
            p.tipoDeAplicacao === tipoDeAplicacao &&
            p.idStatus === STATUS_PROCESSO.APROVADO.ID &&
            new Date(p.dataHoraCadastroSemFormatar).getMonth() + 1 === mes
        )
        .reduce((acc, p) => {
          acc += p.valorMinimoParaCobranca;
          return parseFloat(acc.toFixed(2));
        }, 0);
    },

    popularGraficoDePizza(tipoDeAplicacao) {
      return this.processosCobrados.filter(
        (p) =>
          p.idStatus === STATUS_PROCESSO.APROVADO.ID &&
          p.tipoDeAplicacao === tipoDeAplicacao
      ).length;
    },

    popularGraficoDePizzaDeStatusDeProcessos(status) {
      return this.processosCobrados.filter((p) => p.idStatus === status).length;
    },

    atribuirValoresAoGraficoDePizzaDeStatusDeProcesso() {
      this.quantidadeProcessoAprovado =
        this.popularGraficoDePizzaDeStatusDeProcessos(
          STATUS_PROCESSO.APROVADO.ID
        );
      this.quantidadeProcessoPendente =
        this.popularGraficoDePizzaDeStatusDeProcessos(
          STATUS_PROCESSO.PENDENTE.ID
        );
      this.quantidadeProcessoReprovado =
        this.popularGraficoDePizzaDeStatusDeProcessos(
          STATUS_PROCESSO.REPROVADO.ID
        );
      this.quantidadeProcessoCancelado =
        this.popularGraficoDePizzaDeStatusDeProcessos(
          STATUS_PROCESSO.CANCELADO.ID
        );

      this.isLoading = false;
    },

    atribuirValoresAoGraficoDePizzaDeQuantidadeDeProcessos() {
      this.quantidadeProcessoDesktop = this.popularGraficoDePizza(
        TIPO_APLICACAO.DESKTOP.ID
      );
      this.quantidadeProcessoMobile = this.popularGraficoDePizza(
        TIPO_APLICACAO.MOBILE.ID
      );
      this.quantidadeProcessoWeb = this.popularGraficoDePizza(
        TIPO_APLICACAO.WEB.ID
      );
    },

    atribuirValoresAoGraficoDeBarras() {
      const mesAtual = this.subtrairMeses(new Date(), 0);
      const mesAnterior = this.subtrairMeses(new Date(), 1);
      const segundoMesAterior = this.subtrairMeses(new Date(), 2);

      this.valorProcessoMesAtualDesktop = this.popularGraficoDeBarras(
        TIPO_APLICACAO.DESKTOP.ID,
        mesAtual
      );
      this.valorProcessoMesAtualMobile = this.popularGraficoDeBarras(
        TIPO_APLICACAO.MOBILE.ID,
        mesAtual
      );
      this.valorProcessoMesAtualWeb = this.popularGraficoDeBarras(
        TIPO_APLICACAO.WEB.ID,
        mesAtual
      );
      this.valorProcessoMesAnteriorDesktop = this.popularGraficoDeBarras(
        TIPO_APLICACAO.DESKTOP.ID,
        mesAnterior
      );
      this.valorProcessoMesAnteriorMobile = this.popularGraficoDeBarras(
        TIPO_APLICACAO.MOBILE.ID,
        mesAnterior
      );
      this.valorProcessoMesAnteriorWeb = this.popularGraficoDeBarras(
        TIPO_APLICACAO.WEB.ID,
        mesAnterior
      );
      this.valorProcessoSegundoMesAnteriorDesktop = this.popularGraficoDeBarras(
        TIPO_APLICACAO.DESKTOP.ID,
        segundoMesAterior
      );
      this.valorProcessoSegundoMesAnteriorMobile = this.popularGraficoDeBarras(
        TIPO_APLICACAO.MOBILE.ID,
        segundoMesAterior
      );
      this.valorProcessoSegundoMesAnteriorWeb = this.popularGraficoDeBarras(
        TIPO_APLICACAO.WEB.ID,
        segundoMesAterior
      );
    },
    subtrairMeses(data, qtdMeses) {
      const resultado = new Date(data),
        mesEsperado = (((data.getMonth() - qtdMeses) % 12) + 12) % 12;
      resultado.setMonth(resultado.getMonth() - qtdMeses);
      if (resultado.getMonth() !== mesEsperado) {
        resultado.setDate(0);
      }
      return resultado.getMonth() + 1;
    },

    navegarParaProcessosFiltradosPorTipo(event) {
      this.$router.push({
        name: "Processos",
        query: {
          tipo: event.point.name.toLowerCase().split(" ")[1],
          dataInicial: utilsData.aplicarMascaraEmData(
            TIPO_FILTRO[
              this.periodoFiltro
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/\s/g, "")
            ].DATA_INICIAL
          ),
          dataFinal: utilsData.aplicarMascaraEmData(
            TIPO_FILTRO[
              this.periodoFiltro
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/\s/g, "")
            ].DATA_FINAL
          ),
        },
      });
    },

    navegarParaProcessosFiltradosStatus(event) {
      this.$router.push({
        name: "Processos",
        query: {
          status: event.point.name.toLowerCase(),
          dataInicial: utilsData.aplicarMascaraEmData(
            TIPO_FILTRO[
              this.periodoFiltro
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/\s/g, "")
            ].DATA_INICIAL
          ),
          dataFinal: utilsData.aplicarMascaraEmData(
            TIPO_FILTRO[
              this.periodoFiltro
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/\s/g, "")
            ].DATA_FINAL
          ),
        },
      });
    },

    navegarParaProcessosFiltradosPorTipoEMes(event) {
      this.$router.push({
        name: "Processos",
        query: {
          mes: event.point.name.toLowerCase(),
          tipo: event.point.series.name.toLowerCase(),
        },
      });
    },

    montarGraficoDemostrativoPorUltimosMeses() {
      Highcharts.chart({
        chart: {
          type: "column",
          height: "362px",
          renderTo: "quantidade",
          style: {
            fontFamily: "Roboto",
          },
        },
        colors: ["#328ABA", "#C55288", "#44B08A"],
        title: {
          useHTML: true,
          style: {
            color: "#000000",
            fontSize: "15px",
            fontWeight: "500",
            align: "center",
          },
          text: "Valor total de processos aprovados",
        },
        xAxis: {
          type: "category",
          title: {
            text: null,
          },
        },
        yAxis: {
          min: 0,
          visible: true,
          title: {
            text: "",
            align: "high",
          },
          labels: {
            overflow: "justify",
          },
        },
        legend: {
          itemStyle: {
            fontWeight: "400",
            fontSize: "13px",
            color: "#000000",
          },
        },
        tooltip: {
          formatter: function () {
            return (
              this.point.name +
              "<br/>" +
              this.series.name +
              ": " +
              "<b>R$ " +
              Highcharts.numberFormat(this.point.y, 2, ",", ".") +
              "</b><br/>"
            );
          },
          style: { opacity: "1", color: "#000000", fontSize: "13px" },
          backgroundColor: "#FFF",
          borderWidth: 2,
        },
        plotOptions: {
          column: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
          series: {
            events: {
              click: this.navegarParaProcessosFiltradosPorTipoEMes,
            },
            dataLabels: {
              enabled: false,
              shadow: true,
              style: {
                fontWeight: "900",
                color: "#000000",
              },
              format: "R$ {point.y:,.2f}",
            },
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Desktop",
            data: [
              {
                name: MES_ITEMS[this.subtrairMeses(this.mes, 2) - 1],
                y: this.valorProcessoSegundoMesAnteriorDesktop,
              },
              {
                name: MES_ITEMS[this.subtrairMeses(this.mes, 1) - 1],
                y: this.valorProcessoMesAnteriorDesktop,
              },
              {
                name: MES_ITEMS[this.subtrairMeses(this.mes, 0) - 1],
                y: this.valorProcessoMesAtualDesktop,
              },
            ],
          },
          {
            name: "Mobile",
            data: [
              {
                name: MES_ITEMS[this.subtrairMeses(this.mes, 2) - 1],
                y: this.valorProcessoSegundoMesAnteriorMobile,
              },
              {
                name: MES_ITEMS[this.subtrairMeses(this.mes, 1) - 1],
                y: this.valorProcessoMesAnteriorMobile,
              },
              {
                name: MES_ITEMS[this.subtrairMeses(this.mes, 0) - 1],
                y: this.valorProcessoMesAtualMobile,
              },
            ],
          },
          {
            name: "Web",
            data: [
              {
                name: MES_ITEMS[this.subtrairMeses(this.mes, 2) - 1],
                y: this.valorProcessoSegundoMesAnteriorWeb,
              },
              {
                name: MES_ITEMS[this.subtrairMeses(this.mes, 1) - 1],
                y: this.valorProcessoMesAnteriorWeb,
              },
              {
                name: MES_ITEMS[this.subtrairMeses(this.mes, 0) - 1],
                y: this.valorProcessoMesAtualWeb,
              },
            ],
          },
        ],
      });
    },

    montarGraficoDemostrativoPorcentagemDosUltimosMeses() {
      Highcharts.chart({
        colors: ["#328ABA", "#C55288", "#44B08A"],
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          renderTo: "porcentagem",
          style: {
            fontFamily: "Roboto",
          },
        },
        title: {
          useHTML: true,
          style: {
            color: "#000000",
            fontSize: "15px",
            fontWeight: "500",
            align: "center",
          },
          text: "Quantidade de processos aprovados",
        },
        tooltip: {
          formatter: function () {
            return (
              this.point.name +
              "<br/><b>Quantidade: " +
              parseInt(Highcharts.numberFormat(this.point.y)) +
              "</b><br/>"
            );
          },
          style: { color: "#000000", fontSize: "13px" },
          backgroundColor: "#FFF",
          borderWidth: 2,
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            events: {
              click: this.navegarParaProcessosFiltradosPorTipo,
            },
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              style: {
                fontWeight: "bold",
                color: "#FFFFFF",
              },
              enabled: true,
              format: "{point.percentage:,.1f}%",
              distance: -50,
              filter: {
                property: "percentage",
                operator: ">",
                value: 4,
              },
            },
            showInLegend: true,
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            colorByPoint: true,
            data: [
              {
                name: "Processos Desktop",
                y: this.quantidadeProcessoDesktop,
                sliced: true,
              },
              {
                name: "Processos Mobile",
                y: this.quantidadeProcessoMobile,
              },
              {
                name: "Processos Web",
                y: this.quantidadeProcessoWeb,
              },
            ],
          },
        ],
      });
    },
    montarGraficoDemostrativoQuantidadeProcessosPorStatus() {
      Highcharts.chart({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          renderTo: "grafico-por-status",
          style: {
            fontFamily: "Roboto",
          },
        },
        colors: ["#44B08A", "#328ABA", "#f49835", "#C55288"],
        title: {
          useHTML: true,
          style: {
            color: "#000000",
            fontSize: "15px",
            fontWeight: "500",
            align: "center",
          },
          text: "Quantidade de processos por status",
        },
        tooltip: {
          formatter: function () {
            return (
              this.point.name +
              "<br/><b>Quantidade: " +
              parseInt(Highcharts.numberFormat(this.point.y)) +
              "</b><br/>"
            );
          },
          style: { color: "#000000", fontSize: "13px" },
          backgroundColor: "#FFF",
          borderWidth: 2,
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            events: {
              click: this.navegarParaProcessosFiltradosStatus,
            },
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              style: {
                fontWeight: "bold",
                color: "#FFFFFF",
              },
              enabled: true,
              format: "{point.percentage:,.1f}%",
              distance: -50,
              filter: {
                property: "percentage",
                operator: ">",
                value: 4,
              },
            },
            showInLegend: true,
          },
        },
        series: [
          {
            colorByPoint: true,
            data: [
              {
                name: "Aprovado",
                y: this.quantidadeProcessoAprovado,
                sliced: true,
              },
              {
                name: "Pendente",
                y: this.quantidadeProcessoPendente,
              },
              {
                name: "Reprovado",
                y: this.quantidadeProcessoReprovado,
              },
              {
                name: "Cancelado",
                y: this.quantidadeProcessoCancelado,
              },
            ],
          },
        ],
        credits: {
          enabled: false,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: $cor_primaria;
}

.circulo-carregamento {
  width: calc(100vw - 55px);
  height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.circulo-carregamento p {
  color: $cor_primaria;
  font-size: 18px;
}
</style>
