var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"elevation-3"},[_c('v-card-title',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.pesquisar),callback:function ($$v) {_vm.pesquisar=$$v},expression:"pesquisar"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.colunas,"items":_vm.logsFiltrados,"search":_vm.pesquisar},scopedSlots:_vm._u([{key:"item.idString",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.idString)+" ")]}},{key:"item.codigoCliente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.codigoClienteString)+" ")]}},{key:"item.jiraString",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_black","href":item.jiraString}},[_vm._v(_vm._s(item.jiraString.length > 50 ? item.jiraString.substr(0, 49) + "..." : item.jiraString))])]}},{key:"item.original",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(item.valorOriginal)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20","color":"primary"},on:{"click":function($event){return _vm.manipuladorModal(item, _vm.logOriginal)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-find ")])]}}:null],null,true)},[_c('span',[_vm._v("Visualizar valor original")])])]}},{key:"item.atual",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20","color":"primary"},on:{"click":function($event){return _vm.manipuladorModal(item, !_vm.logOriginal)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-find ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar valor atual")])])]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"titulo-modal"},[_vm._v("Valor "+_vm._s(_vm.tituloLog))])]),_c('v-card-text',[_c('v-form',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Criado por","readonly":"","error":_vm.verificaExistenciaNoArray('LoginUsuario'),"hint":_vm.verificaExistenciaNoArray('LoginUsuario')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.loginUsuario),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "loginUsuario", $$v)},expression:"processoCobrado.loginUsuario"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Linha de produto","readonly":"","error":_vm.verificaExistenciaNoArray('IdSetor'),"hint":_vm.verificaExistenciaNoArray('IdSetor')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.setorString),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "setorString", $$v)},expression:"processoCobrado.setorString"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Status","readonly":"","error":_vm.verificaExistenciaNoArray('IdStatus'),"hint":_vm.verificaExistenciaNoArray('IdStatus')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.statusString),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "statusString", $$v)},expression:"processoCobrado.statusString"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Link da tarefa","readonly":"","error":_vm.verificaExistenciaNoArray('LinkTarefaJira'),"hint":_vm.verificaExistenciaNoArray('LinkTarefaJira')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.linkTarefaJira),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "linkTarefaJira", $$v)},expression:"processoCobrado.linkTarefaJira"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Código do cliente","readonly":"","error":_vm.verificaExistenciaNoArray('CodigoCliente'),"hint":_vm.verificaExistenciaNoArray('CodigoCliente')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.codigoCliente),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "codigoCliente", $$v)},expression:"processoCobrado.codigoCliente"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Tipo de aplicação","readonly":"","error":_vm.verificaExistenciaNoArray('TipoDeAplicacao'),"hint":_vm.verificaExistenciaNoArray('TipoDeAplicacao')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.tipoDeAplicacao),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "tipoDeAplicacao", $$v)},expression:"processoCobrado.tipoDeAplicacao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Valor Hora/Homem Programação","readonly":"","error":_vm.verificaExistenciaNoArray('ValorHoraHomemProgramacao'),"hint":_vm.verificaExistenciaNoArray('ValorHoraHomemProgramacao')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.valorHoraHomemProgramacao),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "valorHoraHomemProgramacao", $$v)},expression:"processoCobrado.valorHoraHomemProgramacao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Valor Hora/Homem Qualidade","readonly":"","error":_vm.verificaExistenciaNoArray('ValorHoraHomemQualidade'),"hint":_vm.verificaExistenciaNoArray('ValorHoraHomemQualidade')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.valorHoraHomemQualidade),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "valorHoraHomemQualidade", $$v)},expression:"processoCobrado.valorHoraHomemQualidade"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Valor Hora/Homem Suporte","readonly":"","error":_vm.verificaExistenciaNoArray('ValorHoraHomemSuporte'),"hint":_vm.verificaExistenciaNoArray('ValorHoraHomemSuporte')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.valorHoraHomemSuporte),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "valorHoraHomemSuporte", $$v)},expression:"processoCobrado.valorHoraHomemSuporte"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Valor Hora/Homem Comercial","readonly":"","error":_vm.verificaExistenciaNoArray('ValorHoraHomemComercial'),"hint":_vm.verificaExistenciaNoArray('ValorHoraHomemComercial')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.valorHoraHomemComercial),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "valorHoraHomemComercial", $$v)},expression:"processoCobrado.valorHoraHomemComercial"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas programação","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasProgramacaoHoraNormal'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasProgramacaoHoraNormal'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasProgramacaoHoraNormal
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasProgramacaoHoraNormal", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasProgramacaoHoraNormal\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas programação Extra 50%","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasProgramacaoHoraExtraCinquentaPorCento'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasProgramacaoHoraExtraCinquentaPorCento'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasProgramacaoHoraExtraCinquentaPorCento
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasProgramacaoHoraExtraCinquentaPorCento", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasProgramacaoHoraExtraCinquentaPorCento\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas programação Extra 100%","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasProgramacaoHoraExtraCemPorCento'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasProgramacaoHoraExtraCemPorCento'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasProgramacaoHoraExtraCemPorCento
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasProgramacaoHoraExtraCemPorCento", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasProgramacaoHoraExtraCemPorCento\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas qualidade","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasQualidadeHoraNormal'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasQualidadeHoraNormal'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasQualidadeHoraNormal
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasQualidadeHoraNormal", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasQualidadeHoraNormal\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas qualidade Extra 50%","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasQualidadeHoraExtraCinquentaPorCento'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasQualidadeHoraExtraCinquentaPorCento'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasQualidadeHoraExtraCinquentaPorCento
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasQualidadeHoraExtraCinquentaPorCento", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasQualidadeHoraExtraCinquentaPorCento\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas qualidade Extra 100%","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasQualidadeHoraExtraCemPorCento'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasQualidadeHoraExtraCemPorCento'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasQualidadeHoraExtraCemPorCento
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasQualidadeHoraExtraCemPorCento", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasQualidadeHoraExtraCemPorCento\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas Suporte","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasSuporteHoraNormal'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasSuporteHoraNormal'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasSuporteHoraNormal
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasSuporteHoraNormal", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasSuporteHoraNormal\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas suporte Extra 50%","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasSuporteHoraExtraCinquentaPorCento'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasSuporteHoraExtraCinquentaPorCento'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasSuporteHoraExtraCinquentaPorCento
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasSuporteHoraExtraCinquentaPorCento", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasSuporteHoraExtraCinquentaPorCento\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas suporte Extra 100%","readonlyx":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasSuporteHoraExtraCemPorCento'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasSuporteHoraExtraCemPorCento'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasSuporteHoraExtraCemPorCento
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasSuporteHoraExtraCemPorCento", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasSuporteHoraExtraCemPorCento\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas comercial","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasComercialHoraNormal'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasComercialHoraNormal'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasComercialHoraNormal
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasComercialHoraNormal", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasComercialHoraNormal\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas comercial Extra 50%","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasComercialHoraExtraCinquentaPorCento'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasComercialHoraExtraCinquentaPorCento'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasComercialHoraExtraCinquentaPorCento
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasComercialHoraExtraCinquentaPorCento", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasComercialHoraExtraCinquentaPorCento\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Horas trabalhadas comercial Extra 100%","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasComercialHoraExtraCemPorCento'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'HorasTrabalhadasComercialHoraExtraCemPorCento'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.horasTrabalhadasComercialHoraExtraCemPorCento
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "horasTrabalhadasComercialHoraExtraCemPorCento", $$v)},expression:"\n                      processoCobrado.horasTrabalhadasComercialHoraExtraCemPorCento\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Custo total com homem hora","readonly":"","error":_vm.verificaExistenciaNoArray('CustoTotalComHomemHora'),"hint":_vm.verificaExistenciaNoArray('CustoTotalComHomemHora')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.custoTotalComHomemHora),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "custoTotalComHomemHora", $$v)},expression:"processoCobrado.custoTotalComHomemHora"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Pis sobre faturamento","readonly":"","error":_vm.verificaExistenciaNoArray('PisSobreFaturamento'),"hint":_vm.verificaExistenciaNoArray('PisSobreFaturamento')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.pisSobreFaturamento),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "pisSobreFaturamento", $$v)},expression:"processoCobrado.pisSobreFaturamento"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Cofins sobre faturamento","readonly":"","error":_vm.verificaExistenciaNoArray('CofinsSobreFaturamento'),"hint":_vm.verificaExistenciaNoArray('CofinsSobreFaturamento')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.cofinsSobreFaturamento),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "cofinsSobreFaturamento", $$v)},expression:"processoCobrado.cofinsSobreFaturamento"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"ISS sobre faturamento","readonly":"","error":_vm.verificaExistenciaNoArray('IssSobreFaturamento'),"hint":_vm.verificaExistenciaNoArray('IssSobreFaturamento')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.issSobreFaturamento),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "issSobreFaturamento", $$v)},expression:"processoCobrado.issSobreFaturamento"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"INSS sobre desoneração","readonly":"","error":_vm.verificaExistenciaNoArray('InssSobreDesoneracao'),"hint":_vm.verificaExistenciaNoArray('InssSobreDesoneracao')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.inssSobreDesoneracao),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "inssSobreDesoneracao", $$v)},expression:"processoCobrado.inssSobreDesoneracao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Custos administrativos","readonly":"","error":_vm.verificaExistenciaNoArray('CustosAdministrativos'),"hint":_vm.verificaExistenciaNoArray('CustosAdministrativos')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.custosAdministrativos),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "custosAdministrativos", $$v)},expression:"processoCobrado.custosAdministrativos"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Comissionamento comercial","readonly":"","error":_vm.verificaExistenciaNoArray('ComissionamentoComercial'),"hint":_vm.verificaExistenciaNoArray('ComissionamentoComercial')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.comissionamentoComercial),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "comissionamentoComercial", $$v)},expression:"processoCobrado.comissionamentoComercial"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Total despesas sem IrpjCsllAdicIr sobre lucro","readonly":"","error":_vm.verificaExistenciaNoArray(
                        'TotalDespesasSemIrpjCsllAdicIrSobreLucro'
                      ),"hint":_vm.verificaExistenciaNoArray(
                        'TotalDespesasSemIrpjCsllAdicIrSobreLucro'
                      )
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(
                      _vm.processoCobrado.totalDespesasSemIrpjCsllAdicIrSobreLucro
                    ),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "totalDespesasSemIrpjCsllAdicIrSobreLucro", $$v)},expression:"\n                      processoCobrado.totalDespesasSemIrpjCsllAdicIrSobreLucro\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"IrpjCsllAdicIr sobre lucro","readonly":"","error":_vm.verificaExistenciaNoArray('IrpjCsllAdicIrSobreLucro'),"hint":_vm.verificaExistenciaNoArray('IrpjCsllAdicIrSobreLucro')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.irpjCsllAdicIrSobreLucro),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "irpjCsllAdicIrSobreLucro", $$v)},expression:"processoCobrado.irpjCsllAdicIrSobreLucro"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Quantidade conexoes","readonly":"","error":_vm.verificaExistenciaNoArray('QuantidadeConexoes'),"hint":_vm.verificaExistenciaNoArray('QuantidadeConexoes')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.quantidadeConexoes),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "quantidadeConexoes", $$v)},expression:"processoCobrado.quantidadeConexoes"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Valor mínimo para recorrência","readonly":"","error":_vm.verificaExistenciaNoArray('ValorMinimoParaRecorrencia'),"hint":_vm.verificaExistenciaNoArray('ValorMinimoParaRecorrencia')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.valorMinimoParaRecorrencia),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "valorMinimoParaRecorrencia", $$v)},expression:"processoCobrado.valorMinimoParaRecorrencia"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Valor mínimo para cobrança","readonly":"","error":_vm.verificaExistenciaNoArray('ValorMinimoParaCobranca'),"hint":_vm.verificaExistenciaNoArray('ValorMinimoParaCobranca')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.valorMinimoParaCobranca),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "valorMinimoParaCobranca", $$v)},expression:"processoCobrado.valorMinimoParaCobranca"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.processoCobrado.justificativaStatus)?_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","label":"Justificativa","readonly":"","error":_vm.verificaExistenciaNoArray('JustificativaStatus'),"hint":_vm.verificaExistenciaNoArray('JustificativaStatus')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.justificativaStatus),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "justificativaStatus", $$v)},expression:"processoCobrado.justificativaStatus"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.processoCobrado.observacao)?_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","label":"observação","readonly":"","error":_vm.verificaExistenciaNoArray('Observacao'),"hint":_vm.verificaExistenciaNoArray('Observacao')
                        ? 'Valor modificado'
                        : '',"persistent-hint":""},model:{value:(_vm.processoCobrado.observacao),callback:function ($$v) {_vm.$set(_vm.processoCobrado, "observacao", $$v)},expression:"processoCobrado.observacao"}}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-tertiary elevation-1",attrs:{"text":"","small":""},on:{"click":_vm.cancelar}},[_vm._v(" Fechar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }