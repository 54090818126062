<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-item to="/processos">
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Processos</v-list-item-title>
        </v-list-item>

        <v-list-item to="/auditoria" v-show="acessoAuditoria">
          <v-list-item-icon>
            <v-icon>mdi-file-chart</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Auditoria</v-list-item-title>
        </v-list-item>

        <v-list-item to="/controle-de-usuarios" v-show="acessoControleDeUsuarios">
          <v-list-item-icon>
            <v-icon>mdi-account-settings</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Controle de usuários</v-list-item-title>
        </v-list-item>

        <v-list-item to="/configuracoes" v-show="acessoConfiguracoes">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Configurações</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dark color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="logo">Gestor Processos Cobrados</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-divider vertical></v-divider>
      <span class="pl-4">
        <span class="icon">
          {{ usuario.login }}
        </span>
        <v-icon class="v-icon-exit mdi mdi-exit-to-app" @click="logout" />
      </span>
    </v-app-bar>
  </div>
</template>

<script>
import utilsStorage from "@/utils/storage";
import usuarioModel from "../../model/usuario-model";
import routes from "@/router/routes";
import { PERFIL_USUARIO } from "@/constants/geral-constants.js";

export default {
  name: "Menu",
  data() {
    return {
      acessoAuditoria: false,
      acessoControleDeUsuarios: false,
      acessoConfiguracoes: false,
      drawer: false,
      rotas: routes,
      usuario: new usuarioModel(),
    };
  },

  methods: {
    logout() {
      utilsStorage.removerAutenticacao();
      this.$router.push({ name: "Login" });
    },

    verificarPerfil() {
      if (this.usuario.perfil.id === PERFIL_USUARIO.ADMIN.ID) {
        this.acessoAuditoria = true;
        this.acessoControleDeUsuarios = true;
        this.acessoConfiguracoes = true;
      }

      if (this.usuario.perfil.id === PERFIL_USUARIO.MANAGER.ID) {
        this.acessoAuditoria = true;
      }

      if (this.usuario.perfil.id === PERFIL_USUARIO.BOARD.ID) {
        this.acessoAuditoria = true;
      }
    },
  },
  mounted() {
    this.usuario = new usuarioModel(JSON.parse(utilsStorage.obterUsuarioAtualStorage()));
    this.verificarPerfil();
  },
};
</script>

<style lang="scss" scoped>
.sair {
  text-transform: none;
  background-color: $white;
  border: 1px solid #dadce0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  letter-spacing: 0.15px;
  margin: 16px;
  outline: 0;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  font-size: 12px;
  color: #5f6368;
}

.v-icon-exit {
  padding: 8px;
  border-radius: 50%;
  margin-left: 12px;
}

button:hover {
  background: #404ea7;
}

/*  mudando o menu lateral */
.v-item--active.v-list-item--active.v-list-item.v-list-item--link,
.v-list .v-list-item--active .v-icon {
  color: $cor_primaria;
}

.v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
  padding-left: 30px;
}
</style>
